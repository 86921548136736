import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormatMoney, groupBy, CopyToClipboard, symbolsDisplayHelper, symbolParseHelper } from '../../common/Helpers';

const TransactionLine = props => {
    const [state, setState] = useState({
        Item: props.Item,
        Recipients: [],
        Senders: [],
        Exchange: null,
        selectedSymbolObj: props.selectedSymbolObj
    });
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setExpanded(!expanded);
    }

    // calculateConversion = () => {
    //     var exchangeSymbol = state.selectedSymbolObj;
    //     if (exchangeSymbol &&
    //         exchangeSymbol.latestRecord.symbol !== "USD" &&
    //         exchangeSymbol.rows &&
    //         exchangeSymbol.rows.length > 0) {
    //         console.log("enter on exchange symbol", exchangeSymbol)
    //         //Find the exchange rate closest to the
    //         var singleItem = null;

    //         var filter = exchangeSymbol.rows.filter(x => x.timeUnixFormatUtc <= state.SingleItem.date);

    //         if (filter.length > 0) {
    //             singleItem = filter[0];
    //         }
    //         let symbol = symbolParseHelper(state.selectedSymbolObj);
    //         console.log("enter on exchange symbol", symbol, singleItem);
    //         if (singleItem === null) {
    //             //Means we don't have conversion for specific date, so we return the USD value
    //             return {
    //                 exchange: state.SingleItem.exchangeRate,
    //                 date: state.SingleItem.date,
    //                 symbol: "USD",

    //                 conversion: state.SingleItem.exchangeRate,
    //             };
    //         }
    //         return {
    //             exchange: singleItem.close,
    //             date: singleItem.timeUnixFormatUtc,
    //             symbol: symbol,
    //             conversion: singleItem.close * state.SingleItem.exchangeRate
    //         };
    //     } else {
    //         //Return default conversion value USD
    //         console.log("enter on exchange symbol defaault to USD")
    //         return {
    //             exchange: state.SingleItem.exchangeRate,
    //             date: state.SingleItem.date,
    //             symbol: "USD",

    //             conversion: state.SingleItem.exchangeRate,
    //         };
    //     }
    // }
    const [singleItem, setSingleItem] = useState(null);
    const [symbolSelected, setSymbolSelected] = useState(null);
    const [exchange, setExchange] = useState(0);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let receivers = props.Item.record.filter(x => x.addressType === "receiver");
        let Recipients = groupBy(receivers, "address");
        let singleItemLocal = props.Item.record[0];
        let senders = props.Item.record.filter(x => x.addressType === "sender");
        let Senders = groupBy(senders, "address");
        setState({
            ...state,
            Senders: Senders,
            Recipients: Recipients,
            Item: props.Item
        })

        setSymbolSelected(symbolParseHelper(props.parentState.currencySymbol));
        setSingleItem(singleItemLocal);


        let amountLocal = 0;


        Recipients.forEach(subItem => {
            amountLocal += subItem[0].amount * 1;
        });
        setAmount(amountLocal);
        //    let exchange = this.calculateConversion();
        if (props.parentState.currencySymbol === "USD") {
            setExchange(singleItemLocal.exchangeRate);
        } else {
            //Find the nearest value
            if (props.parentState.forexHistory) {
                let findItem = props.parentState.forexHistory.filter(x => x.date <= singleItemLocal.date)[0];
                if (findItem) {
                    if (findItem.close === singleItemLocal.exchangeRate) {
                        //This means we don't have that date in the server, we must change to default USD

                        setExchange(singleItemLocal.exchangeRate);
                        setSymbolSelected("USD");
                    } else {

                        setExchange(singleItemLocal.exchangeRate * findItem.close);
                    }
                }
            }
        }
    }, [props.Item]);

    if (singleItem === null) {
        return (<></>)
    }

    return (<>    {/*DISPLAY BIG SCREENS */}
        <div className="d-none d-md-block d-lg-block">
            <div className="row mx-0" >
                <div className="col-1 text-left text-truncate text-light px-0">

                    {state.Senders.filter(x => x.filter(y => y.isMember === true).length > 0 ||
                        state.Recipients.filter(x => x.filter(y => y.isMember === true).length > 0)).length >= 1 ?
                        <button className="btn text-light btn-sm " onClick={() => toggle()}>
                            <span className="mr-2">{(props.index + 1) + (props.currentPage * props.recordsPerPage)}</span>
                            {expanded ?
                                <i className="fas fa-caret-up"></i>
                                :
                                <i className="fas fa-caret-down"></i>
                            }

                        </button>
                        :
                        <span className="mr-2">{(props.index + 1) + (props.currentPage * props.recordsPerPage)}</span>
                    }
                </div>
                <div className="col-1  text-left px-0">
                    <small>
                        {moment(singleItem.date * 1000).format("MMM DD, YYYY")}
                    </small>
                    <br />

                    <small>{moment(singleItem.date * 1000).format("hh:mm:ss A z")}</small>
                </div>
                <div className="text-left col-4">
                    <a href={"/" + singleItem.transactionId} target="_blank" className="text-wrap">
                        {singleItem.transactionId}
                    </a>

                </div>
                <div className="col-2 text-wrap text-left pl-0">
                    <span title={amount}>{amount.toFixed(8)} BTC</span>
                    {singleItem.exchangeRate !== null ?
                        <small className="text-muted" title={"1 BTC = " + singleItem.exchangeRate.toFixed(2)}><br />{FormatMoney(amount * exchange)} {symbolSelected}</small> : null}
                </div>
                <div className="col-2 text-wrap text-left pl-0">
                    {singleItem.exchangeRate !== null ?
                        <span>{FormatMoney(exchange.toFixed(2))} {symbolSelected}</span> : null}
                    {singleItem.exchangeRateDate ?

                        <small><br />{moment(singleItem.exchangeRateDate * 1000).format("hh:mm:ss A z")}</small> : null}
                </div>

                <div className="col-2 ml-auto text-wrap text-right px-0">
                    {singleItem.fee.toFixed(8)} BTC
                    {singleItem.exchangeRate !== null ?
                        <small className="text-muted" title={"1 BTC = " + singleItem.exchangeRate.toFixed(2)}><br />{FormatMoney(singleItem.fee * exchange)} {symbolSelected}</small> : null}

                </div>
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </div>
        {/*END DISPLAY BIG SCREENS */}
        {/*MOBILE*/}
        <div className="d-block d-md-none d-lg-none">
            <div className="row mx-0" >
                <div className="col-2 text-center text-truncate text-light pl-0">
                    <span className="mr-2">{(props.index + 1) + (props.currentPage * props.recordsPerPage)}</span>
                    {state.Senders.length > 0 ?
                        <>
                            <br />
                            <button className="btn text-light btn-sm" type="button" onClick={() => this.toggle()}>
                                {expanded ?
                                    <i className="fas fa-caret-up"></i>
                                    :
                                    <i className="fas fa-caret-down"></i>
                                }

                            </button>
                        </>
                        :
                        null
                    }
                </div>
                <div className="col-10 text-wrap text-left">
                    <div className='row '>
                        <div className='col-6 px-0'>

                            <small>
                                {moment(singleItem.date * 1000).format("MMM DD, yyyy")}
                            </small>

                        </div>
                        <div className='col-6 text-right px-0 mx-0' >

                            <small className=''>
                                {exchange !== null ?
                                    FormatMoney(exchange.toFixed(2)) :
                                    null} {symbolSelected}
                            </small>

                        </div>
                        <div className='col-12 text-left  px-0'>
                            <a href={"/" + singleItem.transactionId} target="_blank" className="text-wrap">{singleItem.transactionId}</a>

                            <br />
                            {amount.toFixed(8)} BTC /
                            <small className="text-muted"> {singleItem.fee.toFixed(8)} BTC fee</small>
                        </div>
                    </div>


                </div>
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </div>
        {/*END MOBILE*/}
        {/*EXPANDED VIEW  */}
        {expanded ?
            <div className="row mx-0 bg-altdark">
                <div className="col-12">
                    <div className="row mx-0">
                        <div className="col-5 text-left">
                            <b>From
                                {props.Item.record.filter(x => x.isMember === false && x.addressType === "sender").length > 0 ?
                                    (<small className='ml-2'> (+{props.Item.record.filter(x => x.isMember === false && x.addressType === "sender").length} external addresses)</small>) : null}

                            </b>
                            {state.Senders.map((senders, indexSenders) => {
                                let sender = senders[0]
                                if (!sender.isMember) {
                                    return (<></>)
                                }
                                return (<div className="text-light" key={indexSenders}>
                                    <div className="input-group">
                                        <input className="form-control"
                                            readOnly value={sender.address}></input>
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            CopyToClipboard(sender.address);
                                        }}>
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </div>


                                    <span className="text-light">
                                        {sender.amount.toFixed(8)} BTC
                                    </span>
                                    {singleItem.exchangeRate !== null ?
                                        <small className="text-muted d-inline-block"
                                            title={"1 BTC = " + exchange.toFixed(2)}>
                                            &nbsp;/&nbsp;{FormatMoney((sender.amount * exchange).toFixed(2))} {symbolSelected}</small>
                                        : null}
                                    {/* {sender.isMember ?
                                    <span className="text-success" title="member">m</span>
                                    : null} */}

                                </div>)
                            })}
                        </div>
                        <div className="col-2">
                            <i className="fas fa-angle-right"></i>
                        </div>
                        <div className="col-5 text-left">
                            <b>To

                                {props.Item.record.filter(x => x.isMember === false && x.addressType === "receiver").length > 0 ?
                                    (<small className='ml-2'> (+{props.Item.record.filter(x => x.isMember === false && x.addressType === "receiver").length} external addresses)</small>) : null}

                            </b>
                            {state.Recipients.map((recipients, indexRec) => {
                                let recipient = recipients[0];
                                if (!recipient.isMember) {
                                    return (<></>)
                                }
                                return (<div className="text-light" key={indexRec}>
                                    <div className="input-group">
                                        <input className="form-control" readOnly value={recipient.address}></input>
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            CopyToClipboard(recipient.address);
                                        }}>
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </div>

                                    <span className="text-light"> {recipient.amount.toFixed(8)} BTC </span>
                                    {singleItem.exchangeRate !== null ?
                                        <small className="text-muted d-inline-block"
                                            title={"1 BTC = " + exchange.toFixed(2)}>
                                            &nbsp;/&nbsp;{FormatMoney(recipient.amount * exchange)} {symbolSelected}</small>
                                        : null}
                                    {/* {recipient.isMember ?
                                    <span className="text-success ">m</span>
                                    :
                                    null
                                } */}
                                </div>)
                            })}
                        </div>
                    </div>
                </div>

            </div>
            : null}
    </>);
}


// class TransactionLine extends React.Component {







export default TransactionLine;
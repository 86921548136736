import React, { useEffect, useState } from "react";
import {
  NumberFormat,
  FormatMoney,
  symbolParseHelper,
} from "../../common/Helpers";
import moment from "moment";

import { useHistory, useParams } from "react-router-dom";

import html2pdf from "html2pdf.js";
import api from "../../common/api";
const PrintConfirmedTransactions = (props) => {
  let { id, address, currency } = useParams();
  const [details, setDetails] = useState(null);
  let currentPrice = 0;
  let differenceValue = 0;
  let isALoss = false;

  const [selectedAddress, setSelectedAddress] = useState(null);

  ///METHOD USED TO MARK SELECTED ADDRESS
  const loadSelectedAddress = () => {
    if (address) {
      //Check on senders first
      let foundRecord = null;
      let method = "";

      if ( details && details.transactionDetails.vin.length > 0) {
        var record = details.transactionDetails.vin.filter(
          (x) => x.scriptSig !== null && x.scriptSig.address === address
        )[0];
        if (record) {
          foundRecord = record;
          method = "sending";
        }
      }

      if (details && details.transactionDetails.vout.length > 0) {
        record = details.transactionDetails.vout.filter(
          (x) => x.scriptPubKey.address === address
        )[0];
        if (record) {
          foundRecord = record;
          method = "receiving";
        }
      }
      if (foundRecord !== null) {
        let historicalPrice =
          foundRecord.value *
          calcConversion(
            details.historicalExchangeRate
              ? details.historicalExchangeRate
              : details.currentExchangeRate,
            details.forexHistory
              ? details.forexHistory
              : details.forexCurrentExchangeRate
          );

        setSelectedAddress({
          addressInfo: foundRecord,
          historicalPrice: historicalPrice,
          //latest: selectedSymbolObj.close,
          btcPrice: foundRecord.value,
          //conversionExchange: selectedSymbolObj,
          method: method,
        });
      }
    }
  };

  const GeneratePDF = () => {
    let element = document.getElementById("toprint");
    var opt = {
      margin: [0, 0, 0, 0],
      filename: "transaction_" + id + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  };

  const calcConversion = (usdValue, fiatValue) => {
    if (details.currencySymbol === "USD") {
      return usdValue ? usdValue.close : 1;
    } else {
      return usdValue.close * (fiatValue ? fiatValue.close : 1);
    }
  };
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    Symbols: [],
    SymbolExchange: [],

    GeneralTransactionInfo: null,
    TradeData: null,
    Instance: null,
    BlockchainData: null,
    firstTime: true,
    currentPriceBTCUSD: "-",

    Quantity: 10,
    Page: 0,
    showTransactionDetails: false,
  });

  const request = async (reset) => {
    try {
      let model = {
        Platform: "",
        IP: "",
        Query: id ? id : "",
        Country: "",
        City: "",
        Browser: "",
        Latitude: "",
        Longitude: "",
        Currency: "USD",
      };
      setLoading(true);

      //   let params ="limit="+copy.Quantity+"&offset="+(copy.Page*copy.Quantity)+"&publicKey="+ obj.Query;
      let requestAddress = "";

      requestAddress = "TransactionDetails/QueryV2"; // "RequestHistoric/TransactionDetails";

      //"RequestHistoric/Query2"
      let response = await api.postAction(requestAddress, model);

      setLoading(false);

      if (response.status === 200) {
        setDetails(response.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    request();
  }, []);

  useEffect(()=>{
    loadSelectedAddress();
    setTimeout(()=>{
      GeneratePDF();
    }, 1000)
  },[details])
 
  if (details === null) {
    return <></>;
  }
document.getElementsByTagName("body")[0].setAttribute("class", "");
  return (
    <div className="container">
      <div className="col-12 mt-4 text-dark" id="toprint">

{/* LOGO */}
<div className="text-center mb-4">
  <h4>
<i className="fab fa-bitcoin text-warning mt-1 fa-2x" style={{
                        display: "inline",
                        verticalAlign: "middle"
                    }}></i>&nbsp;
                    Bitcoin Reports
                    </h4>
</div>

        {/* HIGH LATED */}
        {selectedAddress ? (
          <div className={"  mb-4 "}>
            <div className=" text-center">
              {selectedAddress.method === "sending" ? (
                <h2>
                  You have sent{" "}
                  {FormatMoney(
                    selectedAddress.btcPrice *
                      calcConversion(
                        details.historicalExchangeRate
                          ? details.historicalExchangeRate
                          : details.currentExchangeRate,
                        details.forexHistory
                          ? details.forexHistory
                          : details.forexCurrentExchangeRate
                      )
                  )}{" "}
                  {symbolParseHelper(details.currencySymbol)}
                </h2>
              ) : (
                <h2>
                  You have been paid{" "}
                  {FormatMoney(
                    selectedAddress.btcPrice *
                      calcConversion(
                        details.historicalExchangeRate
                          ? details.historicalExchangeRate
                          : details.currentExchangeRate,
                        details.forexHistory
                          ? details.forexHistory
                          : details.forexCurrentExchangeRate
                      )
                  )}{" "}
                  {symbolParseHelper(details.currencySymbol)}
                </h2>
              )}

              <h6>
                {!details.transactionDetails.time
                  ? "-"
                  : moment(details.transactionDetails.time * 1000).format(
                      "MMM DD, YYYY hh:mm:ss A"
                    ) + " GMT(-04:00)"}
              </h6>
            </div>
          </div>
        ) : null}
        {/* END HIGHLATED */}
        <div className={" "}>
          <div className="">
            <div className="row mx-0">
              <div className="col-12 text-left">
                Transaction identified as <br />
                <b className="text-wrap"> {id} </b>
                <br />
                that ocurred on{" "}
                <b style={{ fontSize: "22px" }}>
                  {details.transactionDetails.time === 0
                    ? "-"
                    : moment(details.transactionDetails.time * 1000).format(
                        "MMM DD, YYYY hh:mm:ss A"
                      ) + " GMT(-04:00)"}
                </b>
                &nbsp;is&nbsp;
                <small className="badge badge-success">Confirmed</small>
                &nbsp;at height&nbsp;
                {details.transactionDetails.minedInBlockHeight ? (
                  <b>
                    {NumberFormat(
                      details.transactionDetails.minedInBlockHeight
                    )}
                    .
                  </b>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className=" ">
            <div className="row mx-0">
              <div className="col-12 text-left">
                {details.transactionDetails.vout.map((recipient) => {
                  let amount = recipient.value;
                  let addressFromScript = recipient.scriptPubKey.address;
                  let historicalPrice =
                    amount *
                    calcConversion(
                      details.historicalExchangeRate
                        ? details.historicalExchangeRate
                        : details.currentExchangeRate,
                      details.forexHistory
                        ? details.forexHistory
                        : details.forexCurrentExchangeRate
                    );

                  let shouldDisplay =
                    address === undefined || address === "undefined"
                      ? true
                      : address === addressFromScript;

                  if (address === addressFromScript) {
                    currentPrice =
                      amount *
                      calcConversion(
                        details.currentExchangeRate,
                        details.forexCurrentExchangeRate
                      );

                    if (recipient.scriptPubKey.asm.includes("OP_RETURN")) {
                      shouldDisplay = false;
                    } else {
                      differenceValue = currentPrice - historicalPrice;
                      console.log(
                        "difference",
                        currentPrice,
                        historicalPrice,
                        differenceValue
                      );
                      isALoss = differenceValue < 0;
                    }
                  }
                  let pathToAddress =
                    "/" +
                    id +
                    "/" +
                    addressFromScript +
                    "/" +
                    symbolParseHelper(details.currencySymbol);
                  // totalTransactionReceived += amount * 1;

                  return (
                    <div
                      className={
                        "text-dark " + (shouldDisplay ? "  px-1" : "d-none")
                      }
                      key={addressFromScript}
                    >
                      <p>
                        {address ? "The address " : null}
                        <b>{addressFromScript}</b>
                        &nbsp;has been paid&nbsp;
                        <span className="text-dark">
                          {" "}
                          {(amount * 1).toFixed(8)} BTC
                        </span>
                        &nbsp;worth&nbsp;
                        <b style={{ fontSize: "22px" }}>
                          {symbolParseHelper(details.currencySymbol)}{" "}
                          {FormatMoney(historicalPrice)}
                        </b>
                        &nbsp;at the time of this transaction.
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="">
            <div className="row mx-0">
              <div className="col-12 text-dark text-left my-2">
                <p>
                  A fee of{" "}
                  <span>
                    {details.transactionDetails.fee.amount <0? 0: FormatMoney(details.transactionDetails.fee.amount, 8) +
                      " BTC"}
                  </span>{" "}
                  was paid by the sender, then worth&nbsp;
                  {symbolParseHelper(details.currencySymbol)}&nbsp;
                  {details.transactionDetails.fee.amount <0? 0:FormatMoney(
                    details.transactionDetails.fee.amount *
                      calcConversion(
                        details.historicalExchangeRate
                          ? details.historicalExchangeRate
                          : details.currentExchangeRate,
                        details.forexHistory
                          ? details.forexHistory
                            ? details.forexHistory
                            : details.forexCurrentExchangeRate
                          : details.forexCurrentExchangeRate
                      )
                  )}
                  . {details.historicalExchangeRate.byMinute ? "At" : "Around"}{" "}
                  the time of this transaction{" "}
                  <b>
                    1 BTC was worth{" "}
                    {FormatMoney(
                      calcConversion(
                        details.historicalExchangeRate
                          ? details.historicalExchangeRate
                          : details.currentExchangeRate,
                        details.forexHistory
                          ? details.forexHistory
                          : details.forexCurrentExchangeRate
                      )
                    )}{" "}
                  </b>{" "}
                  {details.historicalExchangeRate.byMinute ? null : (
                    <span>
                      (accurate to within one hour before the transaction).
                    </span>
                  )}
                </p>
                {address && address !== "undefined" ? (
                  <p>
                    Using the current exchange rate of{" "}
                    <b style={{ fontSize: "22px" }}>
                      {moment(details.currentExchangeRate.date * 1000).format(
                        "MMM DD, YYYY hh:mm:ss A"
                      ) + " GMT(-04:00)"}
                    </b>{" "}
                    where 1 BTC is&nbsp;
                    <b style={{ fontSize: "22px" }}>
                      {FormatMoney(details.currentExchangeRate.close)}{" "}
                      {symbolParseHelper(details.currencySymbol)}
                    </b>
                    &nbsp;we can determine that this transaction is&nbsp;
                    <b>
                      now valued at&nbsp;
                      {symbolParseHelper(details.currencySymbol)}{" "}
                      {FormatMoney(currentPrice)}
                    </b>
                    {differenceValue !== 0 ? (
                      <>
                        &nbsp;which is a{" "}
                        {isALoss ? (
                          <span className="text-danger">
                            loss of {symbolParseHelper(details.currencySymbol)}{" "}
                            {FormatMoney(differenceValue * -1)}
                          </span>
                        ) : (
                          <span className="text-success">
                            gain of {symbolParseHelper(details.currencySymbol)}{" "}
                            {FormatMoney(differenceValue)}
                          </span>
                        )}
                      </>
                    ) : null}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintConfirmedTransactions;

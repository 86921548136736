import React, { useEffect, useState } from "react";
import api from "../common/api";
import { symbolsDisplayHelper } from "../common/Helpers";

const SymbolSelector = props => {

    const [Symbols, setSymbols] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedSymbol, setSelectedSymbol] = useState(props.SymbolSelected)
    useEffect(() => {
        requestSymbols();
    }, []);

    useEffect(() => {
        console.log("selected symbol change", props.SymbolSelected)
        updateSymbol(props.SymbolSelected);

    }, [props.SymbolSelected])

    const requestSymbols = async () => {


        setIsLoading(!isLoading);
        //   let params ="limit="+copy.Quantity+"&offset="+(copy.Page*copy.Quantity)+"&publicKey="+ obj.Query;
        await api.getAction("forex/GetSymbols", "")
            .then(response => {
                setIsLoading(false);

                console.log("response symbols", response);
                let records = [];
                if (response.status === 200) {
                    records = response.data.response;
                }

                records.push({ id: 0, symbol: "USD", name: "US Dollar", decimal: 2, symbolLabel: "USD" })

                setSymbols(records);
            })
            .catch(e => {
                console.error(e);
                setIsLoading(false);
            })
    }

    const updateSymbol = async obj => {
        setSelectedSymbol(obj);
        props.assignConversion(obj);
        console.log("Selected symbol", obj, props);
    }


    return (
        <>
            {isLoading ?
                <div className="spinner-border spinner-border-sm mx-2 mt-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                : null}
            <select className="form-control form-control-sm"
                onChange={(val) => updateSymbol(val.target.value)}
                value={selectedSymbol}>
                <option value="">-</option>
                {Symbols.map((item, index) => {
                    let parsed = symbolsDisplayHelper(item);

                    return (<option value={parsed.symbol} key={index}>{parsed.name}</option>)

                })}
            </select>
        </>)
}

export default SymbolSelector;
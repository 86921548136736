import React from "react";

const Footer = (props) => {
  return (
    <section
      className="about-section text-center py-4 bg-altdark d-print-none"
      id="about"
    >
      <div className="container px-4 px-lg-5 py-4">
        <div className="row gx-4 gx-lg-5 justify-content-center">
          <div className="col-lg-8">
            <h2 className="text-white mb-4">About Us</h2>
            <p className="text-white-50">
              Brought to you by&nbsp;
              <a href="https://twitter.com/BtcCuracao" target="_blank">
                @BTCCuraçao
              </a>
            </p>
            <p className="text-white-50">
              Bitcoin-Reports is a free service for the community. We help you
              generate bitcoin transaction reports for financial reporting
              purposes. Our mission is to teach people and businesses of Curaçao
              about bitcoin, the people’s money.
            </p>
          </div>
        </div>
        <a href="https://btccuracao.com" target="_blank">
          <img
            className="img-fluid my-4"
            src="https://btccuracao.com/img/logo/logo.png"
            alt="..."
          />
        </a>
      </div>
    </section>
  );
};

export default Footer;

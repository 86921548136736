import { Card, Col, Row } from "@themesberg/react-bootstrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import api from "../common/api";
import { FormatMoney, symbolParseHelper, symbolsDisplayHelper } from "../common/Helpers";
import Footer from "./Common/Footer";
import Header from "./Common/Header";

const OnThisDay = props => {
    const [records, setRecords] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(0);
    const [symbols, setSymbols] = useState([]);
    const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
    const [state, setState] = useState({
        RecordsForex: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    //List of all fiat symbols
    const requestSymbols = async () => {


        await api.getAction("forex/GetSymbols", "")
            .then(response => {

                if (response.status === 200) {
                    var sym = response.data.response;
                    sym = sym.sort((a, b) => {
                        const nameA = a.symbol.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.symbol.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })

                    //  copy.Symbols = response.data.response;
                }
                //copy.Symbols.push({ id: 0, symbol: "USD", name: "US Dollar", decimal: 2, symbolLabel: "USD" })
                let newSymbols = response.data.response;
                newSymbols.push({ id: 0, symbol: "USD", name: "US Dollar", decimal: 2, symbolLabel: "USD" })
                setSymbols(newSymbols);
            })
            .catch(e => {
                console.error(e);

            })
    }

    const requestForex = async (symbol) => {
        try {
            if (!symbol) {
                symbol = 0;
            }
            let query = "SymbolId=" + symbol;

            var i = moment.utc(from).unix();//Math.floor(new Date().getTime() / 1000);
            query += "&Date=" + i;

            setIsLoading(true);
            var request = await api.getAction("Forex/OnThisDay", query);
            setIsLoading(false);
            console.log("Response from api", request)
            if (request.data.status === "ok") {
                setRecords(request.data.response);


            } else {
                alert(request.data.response);
            }

        } catch (ex) {
            console.error(ex);
        }
    }


    const handleFromChange = val => {
        setFrom(moment(val).format("YYYY-MM-DD"));


    }

    useEffect(() => {
        requestSymbols();
        requestForex();
    }, [])

    useEffect(() => {
        console.log("selected symbol", selectedSymbol, from);
        //Pending request from server
        requestForex(selectedSymbol);
    }, [selectedSymbol, from]);

    return (<>
        <Header />

        <Row className="my-4">
            <Col xs={12} md={8} className="mx-auto">
                <Card className="shadow-sm bg-dark text-light mb-4">
                    <Card.Header className="row mx-0">
                        <div className="col px-0">
                            <Card.Title>
                                On This Day
                                {isLoading ?
                                <div className="spinner-border spinner-border-sm mx-2 mt-2 " role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : null}
                            </Card.Title>
                            
                        </div>
                        <div className="col-auto text-right">
                         
                            <div className="input-group ml-auto">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Forex:</span>

                                </div>
                                <select className="form-control"
                                    value={selectedSymbol} onChange={e => {
                                        setSelectedSymbol(e.target.value)
                                    }}>
                                    {symbols.map((item, index) => {
                                        return (<option value={item.id} key={index}>{symbolParseHelper(item.symbol)}</option>)
                                    })}

                                </select>
                            </div>
                        </div>
                        <div className="col-auto">


                            <div className="input-group ml-auto">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">From:</span>

                                </div>
                                <input type="date" className="form-control" value={from} onChange={(val) => handleFromChange(val.target.value)} />

                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive">
                        <div className='row bg-altdark py-4 px-2 '>
                            <div className="col-12 p-4">
                                <table className='table text-light '>
                                    <thead>
                                        <tr>
                                            <th colSpan={props.symbol !== 0 ? 3 : 2}>
                                                On this day 1 BTC
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                Date
                                            </th>
                                            <th className='text-right'>USD</th>
                                            {selectedSymbol !== 0 ?
                                                <th className='text-right'>Forex</th>
                                                : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {records.map((item, index) => {
                                            return (<tr key={index}>
                                                <td>
                                                    {moment(item.dateOffset).format("MMM DD, YYYY")}
                                                </td>
                                                <td className='text-right'>
                                                    {FormatMoney(item.btcusdExchangeClose)}
                                                </td>
                                                {selectedSymbol !== 0 ?
                                                    <td className='text-right'>
                                                        {item.symbol} &nbsp;
                                                        {item.usdToSymbol === 0 ? "-" : FormatMoney(item.usdToSymbol)}

                                                    </td>
                                                    : null}
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Footer />
            </Col>
        </Row>
    </>)

}

export default OnThisDay;
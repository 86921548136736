import React, {  useState } from 'react';

import { Modal, Col, Row } from '@themesberg/react-bootstrap';
import moment from 'moment';

//Component
const DateRangeFilter = props => {
    const [from, setFrom] = useState(null);
    const [until, setUntil] = useState(null);

    return (
        <Modal show={props.show} onHide={props.toggle} size='sm' >
            <Modal.Header className='bg-dark text-light' >
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-dark text-light'>
                <Row className="mx-0">
                    <Col xs={6} className="px-1">
                        <label>From</label>
                        <input type="date" className='form-control form-control-sm'
                            value={from}
                            onChange={e => {
                                setFrom(e.target.value);
                                setUntil(e.target.value);
                            }}
                        ></input>
                    </Col>
                    <Col xs={6} className="px-1">
                        <label>Until</label>
                        <input type="date" className='form-control form-control-sm'
                            value={until}
                            onChange={e => {

                                setUntil(e.target.value);
                            }}></input>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='bg-dark text-light'>
                <button className='btn btn-secondary btn-sm'
                    onClick={() => {
                        props.filterDateHandler(null, null);
                        props.toggle();
                    }}>
                    Clean
                </button>
                <button className='btn btn-primary btn-sm'
                    onClick={() => {
                        let fromParsed = moment(new Date(from + " UTC")).utc();//.format("YYYY-MM-DD HH:mm");
                        let untilParsed = moment(new Date(until + " UTC")).utc().add("23", "hours")
                            .add("59", "minutes")
                            .add("59", "seconds");
                        props.filterDateHandler(fromParsed, untilParsed);
                        props.toggle();
                    }}>
                    Filter
                </button>
            </Modal.Footer>


        </Modal>
    )
}

export default DateRangeFilter;
import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';

import { FormatMoney } from '../../common/Helpers';

import moment from 'moment';

import api from '../../common/api';
import { useHistory, useParams } from 'react-router-dom';


const BitcoinHistory = props => {
    const { date } = useParams();
    const history = useHistory();
    const [state, setState] = useState({

        RecordsForex: [],
        From: date ?? moment().format("YYYY-MM-DDTHH:mm"),
        Until: null,
        Symbols: [],
        currentPage: 0,
        recordsPerPage: 1
    })
    const [btcValue, setBTCValue] = useState(1);
    const [fiatValue, setfiatValue] = useState(0);
    const [selectedSymbol, setSelectedSymbol] = useState("0");
    const [records, setRecords] = useState([])

    const doTheMathForFiat = (btcAmount, fiatAmount, USDBTCEquivalent, USDForexEquivalent) => {
        return (btcAmount * fiatAmount * USDBTCEquivalent * USDForexEquivalent);
    }

    const updateSymbol = selected => {
        setSelectedSymbol(selected);

    }
    const request = async () => {
        let query = "";
        query += "Page=" + state.currentPage;
        query += "&Quantity=" + state.recordsPerPage;
        if (state.From !== null) {
            console.log("DATES ===", state.From, "Regular", moment(state.From).format(), "regular unix", "utc", moment(state.From).utc().format())
            let from = moment.utc(moment(state.From).format())//.format("YYYY-MM-DD HH:mm");


            query += "&Before=" + from.unix();
        }
        var request = await api.getAction("ExchangeRate/Historical", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            setRecords(request.data.response);

            if (request.data.response[0]) {

                setfiatValue(request.data.response[0].close * btcValue);
            }


            //  await requestForex(selectedSymbol);
        }
    }

    const requestForex = async (symbol) => {
        try {
            let query = "SymbolId=" + symbol;
            if (state.From !== null) {
                let from = moment.utc(moment(state.From).format('YYYY-MM-DD HH:mm'));
                if (isNaN(from.unix())) {
                    return;
                }
                query += "&Date=" + from.unix();
            }
            query += "&Quantity=" + state.recordsPerPage;
            if (symbol === "0") { //Means USD

                if (records.length > 0) {
                    let fiat = doTheMathForFiat(btcValue, 1, records[0].close, 1);
                    setfiatValue(fiat);
                }
                setState({
                    ...state,
                    RecordsForex: []
                });
                return;
            } else {

                var request = await api.getAction("Forex/ForexByMinute", query);
                console.log("Response from api", request)
                if (request.data.status === "ok") {
                    setState({
                        ...state,
                        RecordsForex: request.data.response,
                    });

                    let recordForex = request.data.response[request.data.response.length - 1];
                    if (recordForex) {
                        let fiat = doTheMathForFiat(btcValue, 1, recordForex.btcusdExchangeClose, recordForex.symbolExchangePriceToUSD);
                        // debugger;
                        setfiatValue(fiat.toFixed(2));
                    }
                } else {
                    alert(request.data.response);
                }
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    const handleFromChange = val => {
        setState({
            ...state,
            From: val,
        })

    }

    //List of all fiat symbols
    const requestSymbols = async () => {

        let copy = state;
        copy.Loading = true;

        setState(copy);

        await api.getAction("forex/GetSymbols", "")
            .then(response => {
                copy.Loading = false;

                setState({ ...state, Loading: false });
                console.log("response symbols", response);
                if (response.status === 200) {
                    var sym = response.data.response;
                    sym = sym.sort((a, b) => {
                        const nameA = a.symbol.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.symbol.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })
                    copy.Symbols = response.data.response;
                }
                copy.Symbols.push({ id: 0, symbol: "USD", name: "US Dollar", decimal: 2, symbolLabel: "USD" })

                setState({ ...state, copy });
            })
            .catch(e => {
                console.error(e);
                copy.Loading = false;
                setState(copy);
            })
    }

    useEffect(() => {

        // request();
        requestSymbols();
    }, []);

    useEffect(() => {
        console.log("selected symbol", selectedSymbol);
        //Pending request from server
        requestForex(selectedSymbol);
    }, [selectedSymbol]);

    useEffect(() => {
        console.log(date)
        setState({
            ...state,
            From: date
        })
    }, [date])
    useEffect(() => {
        console.log(state.From)

        request();
    }, [state])
    return (<>
        <Row>
            <Col xs={12}>

                <Card className="shadow-sm bg-dark text-light mb-4">
                    <Card.Header className="row mx-0">
                        <div className="col px-0">
                            <Card.Title>
                                BTC - Fiat Historical Exchange Rate

                            </Card.Title>
                        </div>
                        <div className="col-auto text-right">
                            {!props.latest ?

                                <div className="input-group ml-auto">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">From:</span>

                                    </div>
                                    <input type="datetime-local" className="form-control" value={state.From} onChange={(val) => {

                                        handleFromChange(val.target.value)
                                        history.push(`/bitcoin/history/${val.target.value}`)
                                    }
                                    } />
                                    <div className="input-group-append">
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            if (selectedSymbol === '0') {
                                                history.push(`/bitcoin/history/${state.From}`)
                                                //request();   
                                            } else {
                                                requestForex(selectedSymbol);
                                            }
                                        }}>
                                            Filter
                                        </button>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive">
                        <div className='row bg-altdark py-4 px-2 '>
                            <div className="col-12 px-0">
                                <small ><b>BTC to Fiat Converter</b></small>
                            </div>
                            <div className='col-5 px-0'>
                                <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        <span className="input-group-text bg-dark text-light">
                                            <i className="fab fa-bitcoin text-warning mt-1"></i>&nbsp;BTC
                                        </span>
                                    </div>

                                    <input type="number" className='form-control bg-dark text-light text-right' value={btcValue}
                                        onChange={e => {
                                            setBTCValue(e.target.value);
                                            if (selectedSymbol.symbol === "USD") {
                                                let fiat = doTheMathForFiat(e.target.value, fiatValue, records[0].close, 1);
                                                setfiatValue(fiat)
                                            } else {
                                                let forexExchange = state.RecordsForex[state.RecordsForex.length - 1];
                                                console.log(forexExchange);
                                                let fiat = 0;
                                                if (forexExchange) {
                                                    fiat = doTheMathForFiat(e.target.value, 1, records[0].close, forexExchange.symbolExchangePriceToUSD);
                                                } else {

                                                    fiat = doTheMathForFiat(e.target.value, 1, records[0].close, 1);
                                                }
                                                setfiatValue(fiat)
                                            }
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className="col-2  text-center">
                                <i className="fas fa-exchange-alt fa-2x"></i>
                                {/* <i className="fas text-light fa-2x fa-exchange"></i> */}

                            </div>

                            <div className='ml-auto col-5 text-right px-0'>
                                <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        {/* <span className="input-group-text bg-dark text-light d-none d-md-block">
                                            <i className="fas fa-usd-circle "></i>
                                        </span> */}
                                        <select className="form-control bg-dark text-light px-1"
                                            value={selectedSymbol}
                                            onChange={e => {
                                                updateSymbol(e.target.value);
                                            }}
                                        >
                                            {state.Symbols && state.Symbols.map((item, index) => {
                                                return (<option value={item.id} key={index}>{item.symbol.replace("USD/", "")}</option>)
                                            })}
                                        </select>

                                    </div>

                                    <input type="number" className='form-control bg-dark text-light text-right' value={fiatValue}
                                        onChange={e => {
                                            setfiatValue(e.target.value);

                                            let btcValue = e.target.value / records[0].close;
                                            btcValue = (btcValue * 1).toFixed(8)
                                            setBTCValue(btcValue);

                                        }}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        {state.RecordsForex.length > 0 ?
                            <>
                                <div className="d-xs-none d-md-block">
                                    <table className="table table-stripped my-4">
                                        <thead>
                                            <tr className='text-light'>
                                                <th>#</th>
                                                <th>Date</th>
                                                {selectedSymbol !== "USD" ?
                                                    <th>Fiat to USD</th>
                                                    : null

                                                }
                                                <th className='text-right'>1 BTC Forex</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.RecordsForex.map((item, index) => {
                                                let currentRecord = state.currentPage === 0 ? index + 1 : (index + 1) + (state.currentPage * state.recordsPerPage)
                                                let date = item.date !== null ? moment.utc(item.dateOffset).format("MMM DD, YYYY hh:mm A") : null;
                                                var value = item.btcusdExchangeClose * item.symbolExchangePriceToUSD;
                                                console.log("forex date", value, item.btcusdExchangeClose, item.symbolExchangePriceToUSD, item);
                                                console.log("===", date, item.dateOffset);

                                                return (<tr key={index} className='text-light'>
                                                    <td>{currentRecord}</td>
                                                    <td>{date}</td>

                                                    {selectedSymbol !== "USD" ?
                                                        <td> {item.symbol} {FormatMoney(item.symbolExchangePriceToUSD, ",", ".")} = 1 USD</td>
                                                        : null

                                                    }
                                                    <td className='text-right'>{item.symbol} {FormatMoney(value, ",", ".")}</td>

                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </> :
                            records.length > 0 ?
                                <>
                                    {/* DESKTOP VIEW */}
                                    <div className="d-xs-none d-md-block">
                                        <table className="table table-stripped my-4">
                                            <thead>
                                                <tr className='text-light'>
                                                    <th>#</th>
                                                    <th>Date</th>

                                                    <th className='text-right'>1 BTC</th>
                                                    {/* {selectedSymbol.symbol !== "0" ?
                                                        <th className='text-right'>USD</th> : null
                                                    } */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records.map((item, index) => {

                                                    let currentRecord = state.currentPage === 0 ? index + 1 : (index + 1) + (state.currentPage * state.recordsPerPage)
                                                    let date = item.date !== null ? moment.unix(item.date).local().format("MMM DD, YYYY hh:mm A") : null;
                                                    console.log("===", selectedSymbol, date, item.dateOffset);

                                                    return (<tr key={index} className='text-light'>
                                                        <td>{currentRecord}</td>
                                                        <td>{selectedSymbol * 1 === 0 || item.dateOffset === undefined ? date : moment(item.dateOffset + 'Z').format("MMM DD, YYYY")}</td>

                                                        <td className='text-right'>{item.currency} {FormatMoney(item.close, ",", ".")}</td>
                                                        {/* {selectedSymbol.symbol !== "0" ?
                                                            <td className='text-right'>USD</td> : null
                                                        } */}
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    { }
                                </>

                                : null}
                    </Card.Body>

                </Card>

            </Col>
        </Row>
    </>);
}

export default BitcoinHistory;
//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "Bitcoin Reports",
    Name: "Bitcoin",
    //baseURl: "https://localhost:5002/api/",
    baseURl: "https://api.bitcoin-reports.com/api/",
    IPGeolocationAPiKey: "a38419ac115a4250834674bdf8f96691",//https://api.ipgeolocation.io/ipgeo?apiKey=,
    CryptoCompareAPIKEY: "a9624c971719df75959c57518d695c01c56e58c353e0a8e68045646455b9ef91",// this is the api provide, but does not work well"adc84373f372bd19d6f0082c70ca5f998d4004c9f204c03b7ea21d95f9f5ff64"
    CryptoapisKey: '9e98c2f3d1d38f461790811c5e4713ced1ced88d'
};

export default Settings;

import React, { useEffect, useState } from "react";
import { FormatMoney, symbolParseHelper } from "../../common/Helpers";
import TransactionsByAddressAlt from "./TransactionsByAddressAlt";
import QRCode from 'react-qr-code';
import DateRangeFilter from "../Common/DateRangeFilter";
import SymbolSelector from "../SymbolSelector";
import { useHistory, useParams } from "react-router-dom";
import api from "../../common/api";
import Settings from "../../common/Settings";
import Pagination from "../Common/Pagination";
import { Col, Row } from "@themesberg/react-bootstrap";

const TransactionsByAddress = props => {
    let queryString = new URLSearchParams(document.location.search);
    const [currentPage, setCurrentPage] = useState(1)
    const [quantity, setQuantity] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const recordsPerPage = [10, 20, 30, 50];
    const [loading, setLoading] = useState(false);
    const { id, address, currency } = useParams();
    const history = useHistory();
    const [summary, setSummary] = useState(null);
    const [AddressDetails, setAddressDetails] = useState(props.result);
    const [showQR, setShowQR] = useState(false);
    const [filterDate, setFilterDate] = useState(null);
    const [filterDateUntil, setFilterDateUntil] = useState(null);

    const [informationPending, setInformationPending] = useState(false);
    const [recordsCount, setRecordsCount] = useState(0);
    const [errors, setErrors] = useState('');


    const filterDateHandler = (from, until) => {
        setFilterDate(from);
        setFilterDateUntil(until);
    }

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = async () => {
        setShowFilter(!showFilter)
    }

    const [balance, setBalance] = useState(0);
    const [spent, setSpent] = useState(0);
    const [received, setReceived] = useState(0)


    const loadTransactions = () => {
        if (!AddressDetails) {
            return;
        }
        console.log("Enter on load transactions")
        let orderList = AddressDetails.transactionDetails;
        if (orderList === null) {
            debugger;
            return;
        }
        if (filterDate) {

            let from = filterDate;
            let until = filterDateUntil;

            orderList = orderList.filter(x => x.date >= from.unix() && x.date <= until.unix());
            console.log("order list after filter", orderList, filterDate, from.format(), from.unix(), until.format(), until.unix());
        }


        let transactionsLocal = [];

        //first lets try to do the exchange value 


        orderList.forEach((item, index) => {
            transactionsLocal.push(<TransactionsByAddressAlt
                result={item} obj={props.obj}
                address={address}
                parentState={AddressDetails}
                recordsPerPage={quantity}
                currentPage={currentPage - 1}
                selectedSymbolObj={AddressDetails.currencySymbol}
                key={index}
                index={index} />)
        })
        return (transactionsLocal);
    }

    const assignConversion = val => {
        console.log("is going to assign the symbol ", val);
        if (!val) {
            val = "USD";
        }
        val = symbolParseHelper(val)
        history.push(`/${id}/${address}/${val}?page=${currentPage}`);
        //props.chooseSymbol(val);

    }

    const checkCount = async () => {
        setLoading(true);
        let requestCount = await api.getAction(`addressDetails/TransactionCount`, `address=${id}`);
        setLoading(false);
        console.log(requestCount);

        if (requestCount.data.status === "ok") {
            let response = requestCount.data.response;
            setRecordsCount(response);

        } else if (requestCount.data.status === "error") {
            setErrors(requestCount.data.response[0].e.message)
        }
    }
    useEffect(() => {
        if (recordsCount.local < recordsCount.remote) {
            //We need to request the missing transactions 

            requestPendingInformation();
        } else {
            //We need to request the data
            bindDataRemote();
        }
    }, [recordsCount])
    const requestPendingInformation = async () => {
        setInformationPending(true);

        let dataRequest = await api.getAction(`addressDetails/pending`, `address=${id}&Limit=${recordsCount.remote}`);
        console.log("pending response", dataRequest.data);
        if (dataRequest.data.status === "error") {
            setLoading(false);
            setInformationPending(false);
            setErrors(dataRequest.data.response);
        }

        await checkCount();


        setInformationPending(false);
    }

    const bindDataRemote = async () => {
        if (id === 'undefined' || id === undefined || recordsCount.local < recordsCount.remote) {
            return;
        }
        await bindDataSummaryRemote();
        await bindDataDetails();
    }
    const bindDataSummaryRemote = async () => {
        setLoading(true);
        let request = await api.getAction(`addressDetails/Summary`, `address=${id}&currency=${!currency ? "USD" : currency}`);
        setLoading(false)
        if (request.data.status === "ok") {
            let response = request.data.response;
            setSummary(response);
            console.log("summary", response);
            // setAddressDetails(props.result);

            setReceived(response.received);
            setSpent(response.spent);
            setBalance(response.received - response.spent);
        }
    }

    const bindDataDetails = async () => {
        setLoading(true);
        console.log(summary);
        if (!summary || summary.amountOfTransactions === 0) {
            console.log("no transactions", "bindDatadetials");
            return;
        }
        let query = `address=${id}&currency=${!currency ? "USD" : currency}&page=${(currentPage - 1)}&quantity=${quantity}`
        let request = await api.getAction(`addressDetails/details`, query);
        setLoading(false)
        if (request.data.status === "ok") {
            let response = request.data.response;
            setAddressDetails(response);
            setTotalPages(response.quantityOfPages);
        }
    }
    const onPageChanged = number => {
        setCurrentPage(number);
        console.log("current page changed", number)
        //queryString.append("page", number);
        let urlPath = `/${id}/${address}/${currency}?page=${number}`
        history.push(urlPath);
        // history.push({
        //     search: queryString.toString()
        // })
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

    }

    useEffect(() => {
        if (id === 'undefined' || id === undefined) {
            return;
        }
        console.log("Enter on id change", id)
        checkCount();
    }, [id])

    useEffect(() => {
        bindDataRemote();
    }, [currency])

    useEffect(() => {
        if (id === 'undefined' || id === undefined) {
            return;
        }
        console.log(recordsCount)
        if (recordsCount !== 0) {
            bindDataDetails();
        }

    }, [currentPage, quantity, summary])


    useEffect(() => {
        if (queryString.get("page") !== null) {
            setCurrentPage(queryString.get("page") * 1)
        }
        console.log("params 2", queryString.get("page"))
    }, [queryString.get("page")])

    return (
        <>
            {showFilter ?
                <DateRangeFilter toggle={toggleFilter} show={showFilter} filterDateHandler={filterDateHandler} />
                : null}
            {errors ?
                <div className="alert alert-danger" role="alert">
                    {errors}
                </div> : null}
            {informationPending || loading ?
                <p className="text-center my-4">
                    <div className="spinner-border mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    We are loading remote data, please wait
                    {/* {recordsCount ? `${recordsCount?.local} / ${recordsCount?.remote} ` : ""} */}
                </p> : null}

            <div className='card shadow-sm bg-dark'>
                {/* HEADER */}
                {summary ?
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col'>

                                <h4 className="text-light text-left">Transactions
                                    <small className="text-light ml-2"> ({summary.amountOfTransactions})</small>
                                </h4>

                                <p className="text-light text-left">
                                    Balance: {(((1) * balance) >= 0 ? ((1) * balance) : 0).toFixed(8)} BTC
                                    <span className="text-muted">/
                                        {balance >= 0 ? FormatMoney(balance * (summary.currencySymbol === "USD" ? summary.currentExchangeRate.close : summary.currentExchangeRate.close * summary.forexCurrentExchangeRate.close)) : 0} {symbolParseHelper(summary.currencySymbol)}</span>
                                    <br />
                                    <small className="text-success ">
                                        Received: {((1) * received).toFixed(8)} BTC
                                        <span className="text-muted">/ {FormatMoney(received * (summary.currencySymbol === "USD" ? summary.currentExchangeRate.close : summary.currentExchangeRate.close * summary.forexCurrentExchangeRate.close))} {symbolParseHelper(summary.currencySymbol)}</span>
                                    </small>
                                    <small className="text-danger ml-2">
                                        Spent: {((1) * spent).toFixed(8)} BTC
                                        <span className="text-muted">/ {FormatMoney(spent * (summary.currencySymbol === "USD" ? summary.currentExchangeRate.close : summary.currentExchangeRate.close * summary.forexCurrentExchangeRate.close))} {symbolParseHelper(summary.currencySymbol)}</span>
                                    </small>

                                </p>
                            </div>
                            <div className='ms-auto col-auto text-right'>
                                <div className='input-group'>
                                    {/* LOAD SYMBOLS */}
                                    <div className='input-group-prepend'>
                                        <button className='btn btn-primary btn-sm' onClick={() => toggleFilter()}>
                                            <i className='fa fa-filter'></i>
                                        </button>
                                    </div>
                                    <SymbolSelector
                                        SymbolSelected={summary.currencySymbol}
                                        //  chooseSymbol={chooseSymbol}
                                        //AddressDetails={AddressDetails}

                                        assignConversion={assignConversion}
                                    />
                                    <div className='input-group-append'>
                                        <div className='btn-group'>
                                            <a className="btn btn-sm btn-primary text-light "
                                                title="Export to CSV"
                                                alt="Export to CSV"
                                                download href={`${Settings.baseURl}RequestHistoric/GenerateCSVForAddress?publicKey=${props.obj.Query}&currency=${currency}`}>
                                                <i className="fas fa-file-csv"></i>
                                            </a>
                                            <a className="btn btn-sm btn-primary text-light  "
                                                title="Export to excel"
                                                alt="Export to excel"
                                                download href={`${Settings.baseURl}RequestHistoric/GenerateExcelByAddress?publicKey=${props.obj.Query}&currency=${currency}`}>
                                                <i className="fas fa-file-excel"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                {/* BODY */}
                {AddressDetails !== null ?
                    <div className='card-body'>
                        {loadTransactions()}
                        <Row>
                            <Col>
                                <Pagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChanged={onPageChanged}
                                />
                            </Col>
                            <Col className="text-right col-auto">
                                <select className="form-control"
                                    value={quantity}
                                    onChange={e => {
                                        setQuantity(e.target.value)
                                        setCurrentPage(0);
                                    }}
                                >
                                    {recordsPerPage.map((item, index) => {
                                        return (<option value={item} key={index}>{item}</option>)
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </div>
                    : null}
                <div className='card-footer'>
                    <div className="col-12 text-light text-center">
                        {showQR ?
                            <div className='my-4 bg-light  p-2 mx-auto text-center' style={{ width: "280px" }}>
                                <QRCode value={window.location.href}></QRCode>
                            </div> : null}
                        <button className={(!showQR ? "" : "d-none ") + 'btn btn-primary '} type="button"
                            title='Show QR Code'
                            onClick={() => {
                                setShowQR(!showQR)
                            }}>
                            <i className='fas fa-qrcode'></i>
                        </button>
                        {/* QR CodE */}
                    </div>
                </div>
            </div>

        </>);
}

export default TransactionsByAddress;
import React, { useEffect, useState } from "react";

import Settings from "../../common/Settings";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { groupBy, FormatMoney } from "../../common/Helpers";

import moment from "moment";
import api from "../../common/api";
import TransactionDetailsPrinting from "./TransactionsDetailsPrinting";

const Print = (props) => {
  let timeOut = 50000;

  let { id } = useParams();
  // const [query, setQuery]= useState( id ? id : "");
  const [state, setState] = useState({
    obj: {
      Platform: "",
      IP: "",
      Query: id ? id : "",
      Country: "",
      City: "",
      Browser: "",
      Latitude: "",
      Longitude: "",
    },
    Symbols: [],
    SymbolSelected: "USD",
    SymbolExchange: [],
    TransactionList: null,
    TransactionDetails: null,
    GeneralTransactionInfo: null,
    TradeData: null,
    Instance: null,
    BlockchainData: null,
    firstTime: true,
    currentPriceBTCUSD: "-",
    Loading: false,
    Quantity: 10,
    Page: 0,
    showTransactionDetails: false,
  });

  useEffect(() => {
    //Did mount
    // connect();
    requestGeneralInfo();
    requestSymbols();
    console.log("id =", id);
    if (state.obj.Query) {
      request(true);
    }

    //Will unmount
    return () => {
      console.log("Component will go =====");
      if (
        state.Instance !== null &&
        state.Instance.readyState === WebSocket.OPEN
      ) {
        let copy = state;
        copy.Instance.send(
          JSON.stringify({
            action: "SubRemove",
            subs: ["5~CCCAGG~BTC~USD"],
          })
        );
        copy.Instance.close();
        setState(copy);
      }
    };
  }, []);

  const requestGeneralInfo = async () => {
    try {
      let obj = state.obj;
      obj.Platform = navigator.platform;
      obj.Browser = navigator.userAgent;

      let additionalDataresponse = await fetch(
        "https://api.ipgeolocation.io/ipgeo?apiKey=" +
          Settings.IPGeolocationAPiKey
      );
      let responseAdditionalData = await additionalDataresponse.json();
      if (
        responseAdditionalData !== undefined &&
        responseAdditionalData !== null
      ) {
        obj.IP = responseAdditionalData.ip;
        obj.Country = responseAdditionalData.country_name;
        obj.City = responseAdditionalData.city;
        obj.Latitude = responseAdditionalData.latitude;
        obj.Longitude = responseAdditionalData.longitude;
      }
      //debugger;
      setState({ ...state, obj: obj });
    } catch (ex) {
      console.error(ex);
    }
  };
  // SYMBOLS
  const requestSymbols = async () => {
    let copy = state;
    copy.Loading = true;

    setState(copy);
    //   let params ="limit="+copy.Quantity+"&offset="+(copy.Page*copy.Quantity)+"&publicKey="+ obj.Query;
    await api
      .getAction("forex/GetSymbols", "")
      .then((response) => {
        copy.Loading = false;

        setState({ ...state, Loading: false });
        console.log("response symbols", response);
        if (response.status === 200) {
          copy.Symbols = response.data.response;
        }
        copy.Symbols.push({
          id: 0,
          symbol: "USD",
          name: "US Dollar",
          decimal: 2,
          symbolLabel: "USD",
        });

        setState({ ...state, copy });
      })
      .catch((e) => {
        console.error(e);
        copy.Loading = false;
        setState(copy);
      });
  };

  //END SYMBOLS

  const request = async (reset) => {
    let copy = state;
    copy.Loading = true;

    setState(copy);

    //   let params ="limit="+copy.Quantity+"&offset="+(copy.Page*copy.Quantity)+"&publicKey="+ obj.Query;
    await api
      .postAction("TransactionDetails/QueryV2", copy.obj)
      .then((response) => {
        copy.Loading = false;

        setState({ ...state, Loading: false });
        console.log("response ", response);
        if (response.status === 200) {
          debugger;
          copy.TransactionDetails = {
            Transaction: response.data.transactionDetails,
            CurrentExchangeRate: response.data.currentExchangeRate,
            HistoricalExchangeRate: response.data.historicalExchangeRate,
          };
        }

        setState({ ...state, copy });
      })
      .catch((e) => {
        console.error(e);
        copy.Loading = false;
        setState(copy);
      });
  };

  console.log("state current value", state);

  return (
    <div>
      <ToastContainer />
      {/* Header */}

      {state.TransactionDetails !== null ? (
        <TransactionDetailsPrinting
          result={state.TransactionDetails}
          obj={state.obj}
          print
        />
      ) : null}
    </div>
  );
};

export default Print;

import React from 'react';

const Header = props => {
    return (
        <nav className="navbar navbar-dark bg-dark static-top">
            <div className="container">
                <a className="navbar-brand" href="/" >
                    <i className="fab fa-bitcoin text-warning mt-1 fa-2x" style={{
                        display: "inline",
                        verticalAlign: "middle"
                    }}></i>&nbsp;
                    Bitcoin Reports</a>
                <a className="ml-auto mx-2 d-none" href="#news">News</a>
                
                <a className='mx-2 ml-auto' href="/">
                    Home
                </a>
                <a className='mx-2' href="/bitcoin/history">
                    Price History
                </a>
                <a className='mx-2' href="/onthisday">
                    On this Day
                </a>
                <a className="mx-2" href="#about">About Us</a>
                <a className="mx-2" href="/#faq">FAQ</a>
                <a className="btn btn-primary ml-4" href="https://twitter.com/BtcCuracao" target="_blank">Contact</a>
            </div>
        </nav>
    )
}

export default Header;
import React from 'react';
class Faq extends React.Component {
    constructor(props) {
        super(props);
        let theState = {
            News: null,
            Loading: false,
            activeCategory: 0,
            activeSection: 0,
        }

        this.state = theState;
    }

    componentDidMount = async () => {
    }

    componentWillUnmount() {

    }

    ActiveArea = (category, section) => {
        let copy = this.state;
        copy.activeCategory = category;
        copy.activeSection = section;
        this.setState(copy);
    }

    render() {
        return (

            <div className="container-fluid py-4">

                <div className="row py-4 my-4">
                    <div className="col-12 text-center">

                        <h2 className="text-white mb-4">Frequent Asked Questions (FAQ)</h2>
                    </div>
                    <div className="col-xl-12 pa-0">

                        <div className="container mt-sm-60 mt-30">
                            <div className="hk-row">
                                <div className="col-xl-4">
                                    <div className="card bg-altdark">
                                        <h6 className="card-header text-light">
                                            Category
                                        </h6>
                                        <ul className="list-group list-group-flush">
                                            {/* <li className={"list-group-item bg-altdark text-light d-flex align-items-center clickable"+(this.state.activeCategory ===0? " active": "")} onClick={()=>this.ActiveArea(0,0)}>
                                    <i className="ion ion-md-unlock mr-15"></i>General
                                </li> */}
                                            <li className={"list-group-item bg-altdark text-light d-flex align-items-center clickable" + (this.state.activeCategory === 0 ? " active" : "")} onClick={() => this.ActiveArea(0, 0)}>
                                                <i className="ion ion-md-unlock mr-15"></i>Bitcoin
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className={"card card-lg bg-altdark text-light" + (this.state.activeCategory === 0 ? "" : " d-none")}>
                                        <div className="accordion accordion-type-2 accordion-flush" id="accordion_2">

                                            <div className="card  bg-altdark text-light">
                                                <div className="card-header d-flex justify-content-between">
                                                    <button className="collapsed btn btn-link" onClick={() => this.ActiveArea(this.state.activeCategory, 1)} >What is an xPub, yPub and zPub</button>
                                                </div>
                                                <div id="collapse_2i" className={"collapse " + (this.state.activeSection === 1 ? " show" : "")} data-parent="#accordion_2">
                                                    <div className="card-body pa-15">
                                                        <p>Extended Public Keys (colloquially "XPUBs") are parent public keys from which many children public keys can be mathematically derived using the BIP32 standard. Each child key represents a Bitcoin address. Therefore, an XPUB represents a unique set of Bitcoin addresses.
                                                        </p>
                                                        <p>
                                                            Wallet software often presents users with an XPUB. Exposure of your XPUB, although it doesn't risk theft of your bitcoin, harms your privacy by allowing anyone with access to your XPUB to know all of your addresses and the details of all transactions sent to and from them.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card  bg-altdark text-light">
                                                <div className="card-header d-flex justify-content-between">
                                                    <button className="collapsed btn btn-link" onClick={() => this.ActiveArea(this.state.activeCategory, 3)} >How far back does your historical prices go?</button>
                                                </div>
                                                <div id="collapse_2i" className={"collapse " + (this.state.activeSection === 3 ? " show" : "")} data-parent="#accordion_2">
                                                    <div className="card-body pa-15">
                                                        <p>We have daily historical price data from August 7th, 2010. We have hourly historical price data from January 1st, 2014. We have per minute historical price data from January 1st, 2015.</p>
                                                        {/* <p>We have daily historical price data from August 7th, 2010. We have hourly historical price data from January 1st, 2014.</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card  bg-altdark text-light">
                                                <div className="card-header d-flex justify-content-between">
                                                    <button className="collapsed btn btn-link" onClick={() => this.ActiveArea(this.state.activeCategory, 2)} >How do I export my xpub from Bluewallet?</button>
                                                </div>
                                                <div id="collapse_2i" className={"collapse " + (this.state.activeSection === 2 ? " show" : "")} data-parent="#accordion_2">
                                                    <div className="card-body pa-15">
                                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/_63s9BwK2ks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>);
    }
}

export default Faq;
import React, { useEffect, useState } from "react";
import {
  NumberFormat,
  FormatMoney,
  CopyToClipboard,
  symbolParseHelper,
} from "../../common/Helpers";
import moment from "moment";
import QRCode from "react-qr-code";
import { useHistory, useParams } from "react-router-dom";
import SymbolSelector from "../SymbolSelector";


const ConfirmedTransactionsDetailsConversational = (props) => {
  const history = useHistory();
  let { id, address, currency } = useParams();
  const [details, setDetails] = useState();
  let currentPrice = 0;
  let differenceValue = 0;
  let isALoss = false;

  const [showQR, setShowQR] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);

  ///METHOD USED TO MARK SELECTED ADDRESS
  const loadSelectedAddress = () => {
    
    if (address) {
      //Check on senders first
      let foundRecord = null;
      let method = "";
      if (!details) {
        return;
      }
      if (details.transactionDetails.vin.length > 0) {
        var record = details.transactionDetails.vin.filter(
          (x) => x.scriptSig !== null && x.scriptSig.address === address
        )[0];
        if (record) {
          foundRecord = record;
          method = "sending";
        }
      }

      if (details.transactionDetails.vout.length > 0) {
        record = details.transactionDetails.vout.filter(
          (x) => x.scriptPubKey.address === address
        )[0];
        if (record) {
          foundRecord = record;
          method = "receiving";
        }
      }
      if (foundRecord !== null) {
        let historicalPrice =
          foundRecord.value *
          calcConversion(
            details.historicalExchangeRate
              ? details.historicalExchangeRate
              : details.currentExchangeRate,
            details.forexHistory
              ? details.forexHistory
              : details.forexCurrentExchangeRate
          );

        setSelectedAddress({
          addressInfo: foundRecord,
          historicalPrice: historicalPrice,
          //latest: selectedSymbolObj.close,
          btcPrice: foundRecord.value,
          //conversionExchange: selectedSymbolObj,
          method: method,
        });
      }
    }
  };

  ///THIS METHOD IS USED TO BRING THE SELECTED VALUE FROM THE INSIDE COMPONENT
  const assignConversion = (val) => {
    console.log("is going to assign the symbol ", val);
    if (!val) {
      val = "USD";
    }
    if (val.includes("/")) {
      val = val.replace("USD/", "");
    }
    history.push(`/${id}/${address}/${val}`);
    //props.chooseSymbol(val);
  };
 


  const calcConversion = (usdValue, fiatValue) => {
    console.log("Enter on calcConversion Method", details)
    if (details.currencySymbol === "USD") {
      return usdValue ? usdValue.close : 1;
    } else {
      return usdValue.close * (fiatValue ? fiatValue.close : 1);
    }
  };

  useEffect(() => {
    setDetails(props.result);
  }, [props.result])

  useEffect(() => {
    if (currency) {
      props.chooseSymbol(currency === "USD" ? currency : "USD/" + currency);
    }
  }, [currency]);
  

  useEffect(()=>{
    console.log(address);
    loadSelectedAddress();
  },[ details, currency])
  if (!details) {
    return (<></>)
  }
  return (
    <div className="col-12 mt-4 text-light" id="toprint">
      {/* HIGH LATED */}
      {selectedAddress ? (
        <div
          className={
            props.print ? "card  mb-4 bg-light" : "card mb-4 shadow-sm bg-dark"
          }
        >
          <div className="card-header text-center">
            {selectedAddress.method === "sending" ? (
              <h2>
                You have sent{" "}
                {FormatMoney(
                  selectedAddress.btcPrice *
                  calcConversion(
                    details.historicalExchangeRate
                      ? details.historicalExchangeRate
                      : details.currentExchangeRate,
                    details.forexHistory
                      ? details.forexHistory
                      : details.forexCurrentExchangeRate
                  )
                )}{" "}
                {symbolParseHelper(details.currencySymbol)}
              </h2>
            ) : (
              <h2>
                You have been paid{" "}
                {FormatMoney(
                  selectedAddress.btcPrice *
                  calcConversion(
                    details.historicalExchangeRate
                      ? details.historicalExchangeRate
                      : details.currentExchangeRate,
                    details.forexHistory
                      ? details.forexHistory
                      : details.forexCurrentExchangeRate
                  )
                )}{" "}
                {symbolParseHelper(details.currencySymbol)}
              </h2>
            )}

            <h6>
              {!details.transactionDetails.time
                ? "-"
                : moment(details.transactionDetails.time * 1000).format(
                  "MMM DD, YYYY hh:mm:ss A"
                ) + " GMT(-04:00)"}
            </h6>
          </div>
        </div>
      ) : null}
      {/* END HIGHLATED */}
      <div
        className={props.print ? "card  bg-light" : "card shadow-sm bg-dark"}
      >
        <div className="card-header ">
          <div className="row mx-0">
            <div
              className={
                props.print ? "d-none" : "col-12 col-md-3 pl-0 ml-auto"
              }
            >
              <div className="input-group d-print-none">
                {/* LOAD SYMBOLS */}
                <SymbolSelector
                  assignConversion={assignConversion}
                  SymbolSelected={details.currencySymbol}
                  TransactionDetails={details}
                />

                <button
                  className="btn btn-sm btn-primary d-print-none"
                  onClick={() => {
                    CopyToClipboard(window.location.href);
                  }}
                >
                  <i className="fas fa-copy"></i>
                </button>
                <button
                  className="btn btn-sm btn-primary ml-2 d-print-none"
                  onClick={() => {
                    window.open(`/print/confirmedTransaction/${id}/${address}/${currency}`)
                    //GeneratePDF();
                  }}
                >
                  <i className="fas fa-file-pdf"></i>
                </button>
              </div>
            </div>

            <div className="col-12 text-left">
              Transaction identified as <br />
              <b className="text-wrap"> {props.obj.Query} </b>
              <button className="btn btn-sm  text-light d-print-none"
               onClick={() => {
                CopyToClipboard(props.obj.Query);
              }}
              >
                <i className="fa fa-copy"></i>
              </button>
              <br />
              that ocurred on{" "}
              <b style={{ fontSize: "22px" }}>
                {details.transactionDetails.time === 0
                  ? "-"
                  : moment(details.transactionDetails.time * 1000).format(
                    "MMM DD, YYYY hh:mm:ss A"
                  ) + " GMT(-04:00)"}
              </b>
              &nbsp;is&nbsp;
              <small className="badge badge-success">Confirmed</small>
              &nbsp;at height&nbsp;
              {details.transactionDetails.minedInBlockHeight ? (
                <b>
                  {NumberFormat(details.transactionDetails.minedInBlockHeight)}.
                </b>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="card-body ">
          <div className="row mx-0">
            <div className="col-12 text-left">
              {details.transactionDetails.vout.map((recipient) => {
                let amount = recipient.value;
                let addressFromScript = recipient.scriptPubKey.address;
                let historicalPrice =
                  amount *
                  calcConversion(
                    details.historicalExchangeRate
                      ? details.historicalExchangeRate
                      : details.currentExchangeRate,
                    details.forexHistory
                      ? details.forexHistory
                      : details.forexCurrentExchangeRate
                  );

                let shouldDisplay =
                  address === undefined || address === "undefined"
                    ? true
                    : address === addressFromScript;

                if (address === addressFromScript) {
                  currentPrice =
                    amount *
                    calcConversion(
                      details.currentExchangeRate,
                      details.forexCurrentExchangeRate
                    );

                  if (recipient.scriptPubKey.asm.includes("OP_RETURN")) {
                    shouldDisplay = false;
                  } else {
                    differenceValue = currentPrice - historicalPrice;
                    console.log(
                      "difference",
                      currentPrice,
                      historicalPrice,
                      differenceValue
                    );
                    isALoss = differenceValue < 0;
                  }
                }
                let pathToAddress =
                  "/" +
                  id +
                  "/" +
                  addressFromScript +
                  "/" +
                  symbolParseHelper(details.currencySymbol);
                // totalTransactionReceived += amount * 1;

                return (
                  <div
                    className={
                      "text-light " + (shouldDisplay ? "  px-1" : "d-none")
                    }
                    key={addressFromScript}
                  >
                    <p>
                      {address ? "The address " : null}
                      <a
                        className="alt px-0 text-break text-left"
                        href={pathToAddress}
                      >
                        {addressFromScript}
                      </a>
                      &nbsp;has been paid&nbsp;
                      <span className="text-light">
                        {" "}
                        {(amount * 1).toFixed(8)} BTC
                      </span>
                      &nbsp;worth&nbsp;
                      <b style={{ fontSize: "22px" }}>
                        {symbolParseHelper(details.currencySymbol)}{" "}
                        {FormatMoney(historicalPrice)}
                      </b>
                      &nbsp;at the time of this transaction.
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row mx-0">
            <div className="col-12 text-light text-left my-2">
              <p>
                A fee of{" "}
                <span>
                  {FormatMoney(details.transactionDetails.fee.amount, 8) +
                    " BTC"}
                </span>{" "}
                was paid by the sender, then worth&nbsp;
                {symbolParseHelper(details.currencySymbol)}&nbsp;
                {FormatMoney(
                  details.transactionDetails.fee.amount *
                  calcConversion(
                    details.historicalExchangeRate
                      ? details.historicalExchangeRate
                      : details.currentExchangeRate,
                    details.forexHistory
                      ? details.forexHistory
                        ? details.forexHistory
                        : details.forexCurrentExchangeRate
                      : details.forexCurrentExchangeRate
                  )
                )}
                . {details.historicalExchangeRate.byMinute ? "At" : "Around"}{" "}
                the time of this transaction{" "}
                <b>
                  1 BTC was worth{" "}
                  {FormatMoney(
                    calcConversion(
                      details.historicalExchangeRate
                        ? details.historicalExchangeRate
                        : details.currentExchangeRate,
                      details.forexHistory
                        ? details.forexHistory
                        : details.forexCurrentExchangeRate
                    )
                  )}{" "}
                  {symbolParseHelper(details.currencySymbol)}
                </b>{" "}
                {details.historicalExchangeRate.byMinute ? null : (
                  <span>
                    (accurate to within one hour before the transaction).
                  </span>
                )}
              </p>
              {address && address !== "undefined" ? (
                <p>
                  Using the current exchange rate of{" "}
                  <b style={{ fontSize: "22px" }}>
                    {moment(details.currentExchangeRate.date * 1000).format(
                      "MMM DD, YYYY hh:mm:ss A"
                    ) + " GMT(-04:00)"}
                  </b>{" "}
                  where 1 BTC is&nbsp;
                  <b style={{ fontSize: "22px" }}>
                    {FormatMoney(details.currentExchangeRate.close)}{" "}
                    {symbolParseHelper(details.currencySymbol)}
                  </b>
                  &nbsp;we can determine that this transaction is&nbsp;
                  <b>
                    now valued at&nbsp;
                    {symbolParseHelper(details.currencySymbol)}{" "}
                    {FormatMoney(currentPrice)}
                  </b>
                  {differenceValue !== 0 ? (
                    <>
                      &nbsp;which is a{" "}
                      {isALoss ? (
                        <span className="text-danger">
                          loss of {symbolParseHelper(details.currencySymbol)}{" "}
                          {FormatMoney(differenceValue * -1)}
                        </span>
                      ) : (
                        <span className="text-success">
                          gain of {symbolParseHelper(details.currencySymbol)}{" "}
                          {FormatMoney(differenceValue)}
                        </span>
                      )}
                    </>
                  ) : null}
                </p>
              ) : null}
            </div>
            <div className="col-12 text-light text-center d-print-none">
              {showQR ? (
                <div
                  className="my-4 bg-light  p-2 mx-auto text-center"
                  style={{ width: "280px" }}
                >
                  <QRCode value={window.location.href}></QRCode>
                </div>
              ) : null}
              <button
                className={(!showQR ? "" : "d-none ") + "btn btn-primary "}
                type="button"
                title="Show QR Code"
                onClick={() => {
                  setShowQR(!showQR);
                }}
              >
                <i className="fas fa-qrcode"></i>
              </button>
              {/* QR Code */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmedTransactionsDetailsConversational;

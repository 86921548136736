import React from 'react';
class About extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div>
     
        {/*<!-- Icons Grid-->*/}
        <section className="features-icons bg-light text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div className="features-icons-icon d-flex">
                            
                                </div>
                            <h3>Brought to you by BTCCuraçao</h3>
                            <p className="lead mb-0">
                            <a href="https://twitter.com/BtcCuracao" className="mx-2">
                                <i className="fab fa-twitter"></i> @BTCCuracao
                                </a>
                                  
                                 <a href="http://btccuracao.com/" className="mx-2"><i className="fas fa-globe"></i> Website</a>
                            </p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
        {/*HIGHLATED Sections */}
          
        <section className="showcase">
            <div className="container-fluid p-0">
    
                <div className="row no-gutters">
                    <div className="col-lg-6 order-lg-2 text-white showcase-img"></div>
                    <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                        <h2>Mission BTCCuraçao</h2>
                        <p className="lead mb-0">
                        To teach people and businesses of Curaçao about bitcoin, the people’s money.
                        </p>
                    </div>
                </div>
            </div>
        </section>
            </div>
        )
    }
}

export default About;
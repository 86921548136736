import React, { useEffect, useState } from 'react';

import { Modal, Col, Row } from '@themesberg/react-bootstrap';
import { db } from '../common/db';
import { toast } from 'react-toastify';

//Component
const PreviousSearch = props => {
    const [Records, setRecords] = useState([])

    const getRecords = async () => {
        let records = await db.table("queryHistory").toArray();
        setRecords(records);


    }
    useEffect(() => {
        console.log("props received on contact", props);
        getRecords();
    }, [props])


    const localAlert = msg => {
        toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const copy = str => {

        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        localAlert("Copied to clipboard");
    }

    const removeItem = async item => {
        await db.table("queryHistory").delete(item.id);
        await getRecords();
    }
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' >
            <Modal.Header className='bg-dark text-light' >
                <Modal.Title>Previous Searches</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-dark text-light'>
                <Row>
                    {Records && Records.map((item, index) => {
                        return (<Col xs={12} key={index}>
                            <div className='input-group'>

                                <div className='input-group-prepend'>

                                    <span className="input-group-text bg-dark text-light"
                                        onClick={() => {
                                            removeItem(item);
                                        }}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </span>
                                </div>
                                <input type="text" className='form-control bg-dark text-light ' defaultValue={item.id}></input>
                                <div className='input-group-append'>
                                    <span className="input-group-text bg-dark text-light" onClick={() => {
                                        copy(item.id)
                                    }}>
                                        <i className="fas fa-copy "></i>
                                    </span>
                                    <a href={"/" + item.id} target="_blank" rel="noreferrer" className="btn btn-secondary text-truncate">

                                        <i className="fas fa-search "></i>
                                    </a>
                                </div>

                            </div>
                            <br />
                            <hr />
                        </Col>);
                    })}


                </Row>
            </Modal.Body>



        </Modal>
    )
}

export default PreviousSearch;
import React, { useEffect } from "react";

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/home";
import About from "./components/about";
//ICONS DEFINITION FONTAWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
  faCheckSquare,
  faCoffee,
  faPlus,
  faTimes,
  faSearch,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Print from "./components/Print/Print";
import BitcoinHistoryPage from "./components/BitcoinHistory/BitcoinHistoryPage";
import OnThisDay from "./components/OnThisDay";
import PrintConfirmedTransactions from "./components/Print/PrintConfirmedTransactions";
library.add(faCheckSquare, faCoffee, faPlus, faTimes, faSearch, faEdit);

const RouteEmpty = ({ component: Component, ...rest }) => {
  //  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    //   const timer = setTimeout(() => setLoaded(true), 1000);
    //   return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )}
    />
  );
};

function App() {
  useEffect(() => {
    // Anything in here is fired on component mount.
    //    theSocket.connect();
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <RouteEmpty exact path="/print/confirmedTransaction/:id/:address?/:currency?" children={<PrintConfirmedTransactions />} />
          <RouteEmpty exact path="/print/:id" children={<Print />} />
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/onthisday">
            <OnThisDay />
          </Route>
          <Route exact path="/:id" children={<Home />} />

          <Route
            exact
            path="/bitcoin/history/:date?"
            children={<BitcoinHistoryPage />}
          />
          <Route path="/:id/:address?/:currency?" component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { FormatMoney, CopyToClipboard, symbolParseHelper } from '../../common/Helpers';
import moment from 'moment';


const TransactionsByAddressAlt = props => {

    const [state, setState] = useState(props.result);
    //console.log("state received", state, "props in general", props);

    const [recipient, setRecipient] = useState([]);
    const [sender, setSender] = useState([])
    const [amount, setAmount] = useState(0);
    const [exchange, setExchange] = useState(0);
    const [item, setItem] = useState(props.result[0]);
    const [Expanded, setExpanded] = useState(false);

    useEffect(() => {
        //   console.log("enter on results update", props.result);
        setState(props.result);
        setRecipient(props.result.filter(x => x.addressType === "receiver"));
        setSender(props.result.filter(x => x.addressType === "sender"));
        let itemAmount = 0;
        state.filter(x => x.addressType === "receiver").forEach(subItem => {

            itemAmount += subItem.amount * 1 + subItem.fee * 1;
        });
        setAmount(itemAmount);
        let itemObjLocal = props.result[0]
        setItem(itemObjLocal);

        let address = props.parentState;

        //Lets find the current value
        if (address.forexHistory && address.forexHistory.length > 0) {
            //Get the nearest exchange rate

            var filterRecords = address.forexHistory.filter(x => x.date <= itemObjLocal.date);
            if (filterRecords.length > 0) {

                setExchange(filterRecords[0].close * itemObjLocal.exchangeRate);
            } else {
                setExchange(itemObjLocal.exchangeRate);
            }
        } else if (itemObjLocal) {

            setExchange(itemObjLocal.exchangeRate);
        } else {
            setExchange(1);
        }

    }, [props.result]);



    const toggle = () => {
        setExpanded(!Expanded);
    }
    //console.log(props.currentPage, props.recordsPerPage, props.index, (props.currentPage*props.recordsPerPage), (props.index + 1)+(props.currentPage*props.recordsPerPage))

    if (!state) {
        return (<></>)
    }
    return (<>
        {/*DISPLAY BIG SCREENS */}
        <div className="d-none d-md-block d-lg-block">
            <div className="row mx-0" >
                <div className="col-1 text-left text-truncate text-light px-0">

                    <span className="mr-2">{(props.index + 1) + (props.currentPage * props.recordsPerPage)}</span>
                    {/* {recipient.filter(x =>x.isMember === true).length > 0 || */}
                    {/* (recipient.filter(x => x.isMember === true).length > 0).length >= 1 ? */}
                    <button className="btn text-light btn-sm " onClick={() => toggle()}>
                        {Expanded ?
                            <i className="fas fa-caret-up"></i>
                            :
                            <i className="fas fa-caret-down"></i>
                        }

                    </button>
                    {/* : */}
                    {/* null} */}
                </div>
                <div className="col-2 text-truncate text-left pl-0">
                    <small>
                        {moment(item.date * 1000).format("MMM DD, YYYY")}
                    </small>
                    <br />

                    <small>{moment(item.date * 1000).format("hh:mm:ss A z")}</small>
                </div>
                <div className=" col-4 text-left">
                    <a href={"/" + item.transactionId} target="_blank" className="text-wrap">
                        {item.transactionId}
                    </a>
                    <button className='btn btn-sm btn-primary mx-2'>
                        <i className='fas fa-copy' onClick={e => {
                            CopyToClipboard(item.transactionId);
                        }}></i>
                    </button>

                </div>
                <div className="col-2 text-truncate text-left pl-0">
                    <span title={amount}>{amount.toFixed(8)} BTC</span>
                    {exchange ?
                        <small className="text-muted" title={"1 BTC = " + exchange.toFixed(2)}><br />
                            {FormatMoney(amount * exchange)} {symbolParseHelper(props.parentState.currencySymbol)}</small> : null}
                </div>
                <div className="col-auto text-truncate text-left pl-0">
                    {exchange ?
                        <>
                            <span>1 BTC / </span><br />
                            <small className='text-muted'>{FormatMoney(exchange.toFixed(2))} {symbolParseHelper(props.parentState.currencySymbol)}</small>
                        </> : null}
                </div>

                <div className="col-auto ml-auto text-truncate text-right pr-0">
                    {item.fee && item.fee.toFixed(8)} BTC
                    {exchange ?
                        <small className="text-muted" title={"1 BTC = " + exchange.toFixed(2)}><br />{FormatMoney(item.fee * exchange)} {symbolParseHelper(props.parentState.currencySymbol)}</small> : null}

                </div>
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </div>
        {/*END DISPLAY BIG SCREENS */}
        {/*MOBILE*/}
        <div className="d-block d-md-none d-lg-none">
            <div className="row mx-0" >
                <div className="col-2 text-center text-truncate text-light pl-0">
                    <span className="mr-2">{(props.index * (props.currentPage > 0 ? props.currentPage : 1) + 1)}</span>

                    <br />
                    <button className="btn text-light btn-sm" type="button" onClick={() => toggle()}>
                        {Expanded ?
                            <i className="fas fa-caret-up"></i>
                            :
                            <i className="fas fa-caret-down"></i>
                        }
                    </button>
                </div>
                <div className="col-10 text-truncate text-left">
                    <div className='row '>
                        <div className='col-6'>

                            <small>
                                {moment(item.date * 1000).format("MMM DD, yyyy")}
                            </small>

                        </div>
                        <div className='col-6 text-right px-0 mx-0' >

                            <small className=''>
                                {exchange ?
                                    FormatMoney(exchange.toFixed(2)) :
                                    null} {symbolParseHelper(props.parentState.currencySymbol)}
                            </small>

                        </div>
                    </div>
                    <br />
                    <a href={"/" + item.transactionId} target="_blank" className="text-wrap">

                        {item.transactionId}
                    </a>
                    <button className='btn btn-sm btn-link mx-2'>
                        <i className='fas fa-copy' onClick={e => {
                            CopyToClipboard(item.transactionId);
                        }}></i>
                    </button>
                    <br />
                    {amount.toFixed(8)} BTC /
                    <small className="text-muted"> {item.fee && item.fee.toFixed(8)} BTC fee</small>
                </div>
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </div>
        {/*END MOBILE*/}
        {/*EXPANDED VIEW  */}
        {Expanded ?
            <div className="row mx-0 bg-altdark" style={{ width: "100%" }}>
                <div className="col-12">
                    <div className="row mx-0">
                        <div className="col-5 text-left">
                            <b>From
                                {sender.filter(x => x.isMember === false).length > 0 ?
                                    (<small className='ml-2'> (+{sender.filter(x => x.isMember === false).length} external addresses)</small>) : null}
                            </b>
                            {sender.map(senders => {
                                let senderItem = senders
                                if (!senderItem.isMember) {
                                    return (<></>)
                                }
                                return (<ul className="text-light" key={senderItem.address}>
                                    {/* <div className="input-group">
                                        <input className="form-control"
                                            readOnly value={senderItem.address}></input>
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            CopyToClipboard(senderItem.address);
                                        }}>
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </div> */}

                                    <li>
                                        <span className="text-light">
                                            {senderItem.amount.toFixed(8)} BTC
                                        </span>
                                        {exchange !== null ?
                                            <small className="text-muted d-inline-block"
                                                title={"1 BTC = " + exchange.toFixed(2)}>
                                                &nbsp;/&nbsp;{FormatMoney((senderItem.amount * exchange).toFixed(2))} {symbolParseHelper(props.parentState.currencySymbol)}</small>
                                            : null}
                                    </li>

                                </ul>)
                            })}
                        </div>
                        <div className="col-2">
                            <i className="fas fa-angle-right"></i>
                        </div>
                        <div className="col-5 text-left">
                            <b>To

                                {recipient.filter(x => x.isMember === false).length > 0 ?
                                    (<small className='ml-2'> (+{recipient.filter(x => x.isMember === false).length} external addresses)</small>) : null}

                            </b>
                            {recipient.map(recipients => {
                                let recipientItem = recipients;
                                if (!recipientItem.isMember) {
                                    return (<></>)
                                }
                                return (<ul className="text-light" key={recipientItem.address}>
                                    {/* <div className="input-group">
                                        <input className="form-control" readOnly value={recipientItem.address}></input>
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            CopyToClipboard(recipientItem.address);
                                        }}>
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </div> */}
                                    <li>
                                        <span className="text-light"> {recipientItem.amount.toFixed(8)} BTC </span>
                                        {exchange !== null ?
                                            <small className="text-muted d-inline-block"
                                                title={"1 BTC = " + exchange.toFixed(2)}>
                                                &nbsp;/&nbsp;{FormatMoney(recipientItem.amount * exchange)} {symbolParseHelper(props.parentState.currencySymbol)}</small>
                                            : null}
                                    </li>
                                </ul>)
                            })}
                        </div>
                    </div>
                </div>

            </div>
            : null}
    </>);
}

export default TransactionsByAddressAlt;
import React from "react";
import API from "../../common/api";
import moment from "moment";
import { CopyToClipboard, FormatMoney } from "../../common/Helpers";

class BlockchainHistory extends React.Component {
  constructor(props) {
    super(props);
    let theState = {
      News: null,
    };

    this.state = theState;
  }

  //REQUEST BLOCKCHAIN HISTORICAL DATA
  requestBlockchain = async () => {
    let copy = this.state;

    await API.getAction("blocks/List", "limit=10").then((response) => {
      copy.BlockchainData = response.data.response;
      this.setState(copy);
      console.log(response);
    });
  };
  componentDidMount = async () => {
    await this.requestBlockchain();
  };

  componentWillUnmount() {}
  copy = (str) => {
    CopyToClipboard(str);
  };

  render() {
    return (
      <>
        <div className="row mx-0">
          <div className="col-auto">
            <h2 className="text-light">Blockchain history</h2>
          </div>
          <div className="col-auto ml-auto d-none">
            <div className="btn-group">
              <button
                className="btn btn-sm text-light"
                onClick={() => this.requestBlockchain()}
              >
                day
              </button>

              <button className="btn btn-sm text-light d-none">4h</button>
              <button className="btn btn-sm text-light d-none">24h</button>
            </div>
          </div>
        </div>
        {this.state.BlockchainData != null ? (
          <>
            {/* MOBILE VIEW */}
            {this.state.BlockchainData.map((element, index) => {
              if (index >= 10) {
                return <div key={index}></div>;
              }
              return (
                <div
                  className="card shadow-sm bg-dark text-light d-block d-md-none my-1"
                  key={index}
                >
                  <div className="card-body p-2 py-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group input-group-sm">
                          <input
                            type="text"
                            className="form-control bg-dark text-light"
                            defaultValue={element.hash}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                this.copy(element.hash);
                              }}
                            >
                              <i className="fas fa-copy"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <small className="text-muted">Height</small>
                        <br />

                        {new Intl.NumberFormat().format(element.height)}
                      </div>
                      {/* <div className="col">
                        <small className="text-muted">Size</small>
                        <br />

                        {new Intl.NumberFormat().format(element.size)}
                      </div> */}
                       <div className="col">
                        <small className="text-muted">USD</small>
                        <br />

                        {element.closeUSDValue ? (
                            <td title={moment(element.usdTime * 1000)}>
                              {FormatMoney(element.closeUSDValue)}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                      </div>
                      <div className="col text-right">
                        <small className="text-muted">
                          {moment(element.timestamp * 1000).format("MMM DD,YY")}
                        </small>
                        <br />
                        {moment(element.timestamp * 1000).format("HH:mm:ss Z")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <small className="text-muted">Miner</small>
                        <br />
                        {element.minerName ? (
                          <small
                            className="badge badge-sm badge-primary clickable"
                            title={element.minerIdentifierBy}
                            onClick={() => {
                              window.open(element.minerLink, "_blank");
                            }}
                          >
                            {element.minerName}
                          </small>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="col">
                        <small className="text-muted">Tx Count</small>
                        <br />

                        {element.transactionCount
                          ? new Intl.NumberFormat().format(
                              element.transactionCount
                            )
                          : "-"}
                      </div>
                      <div className="col text-right">
                        <small className="text-muted">Mined</small>
                        <br />
                        {moment(element.timestamp * 1000).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* DESKTOP VIEW */}
            <div className="card shadow-sm bg-dark d-none d-md-block">
              <div className="card-body table-responsive">
                <table className="table table-dark">
                  <thead>
                    <tr>
                      <th>Height</th>
                      {/* <th>Weight</th> */}
                      <th>Size (B)</th>
                      <th>USD</th>
                      <th>Mined</th>
                      <th>Timestamp</th>
                      <th>Miner</th>
                      <th>Tx Count</th>
                      <th>Block Hash</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.BlockchainData.map((element, index) => {
                      if (index >= 10) {
                        return <tr key={index}></tr>;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            {new Intl.NumberFormat().format(element.height)}
                          </td>
                          {/* <td>{new Intl.NumberFormat().format(element.weight)}</td> */}
                          <td>
                            {new Intl.NumberFormat().format(element.size)}
                          </td>
                          {element.closeUSDValue ? (
                            <td title={moment(element.usdTime * 1000)}>
                              {FormatMoney(element.closeUSDValue)}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          <td>{moment(element.timestamp * 1000).fromNow()}</td>

                          <td>
                            {moment(element.timestamp * 1000).format(
                              "MMM DD,YY"
                            )}
                            <br />
                            <small>
                              {moment(element.timestamp * 1000).format(
                                "HH:mm:ss Z"
                              )}{" "}
                            </small>
                          </td>
                          <td>
                            {element.minerName ? (
                              <small
                                className="badge badge-sm badge-primary clickable"
                                title={element.minerIdentifierBy}
                                onClick={() => {
                                  window.open(element.minerLink, "_blank");
                                }}
                              >
                                {element.minerName}
                              </small>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {element.transactionCount
                              ? new Intl.NumberFormat().format(
                                  element.transactionCount
                                )
                              : "-"}
                          </td>
                          <td className="text-muted">
                            <div className="input-group input-group-sm">
                              <input
                                readOnly
                                className="form-control"
                                value={element.hash}
                              />

                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  this.copy(element.hash);
                                }}
                              >
                                <i className="fas fa-copy"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default BlockchainHistory;

import moment from "moment";
import { toast } from "react-toastify";

export const groupBy = (collection, property) => {
  var i = 0, values = [], result = [];
  for (i; i < collection.length; i++) {
    if (values.indexOf(collection[i][property]) === -1) {
      values.push(collection[i][property]);
      result.push(collection.filter(function (v) { return v[property] === collection[i][property] }));
    }
  }
  return result;
}

export const epochToDateConverter = date => {
  return date * 1000;
}




//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function (n, c, decimal, t) {

  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    decimal = decimal === undefined ? "." : decimal,
    t = t === undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? decimal + Math.abs(n - i).toFixed(c).slice(2) : "");
};


export const NumberFormat = function (item) {
  return new Intl.NumberFormat().format(isNaN(item * 1) ? 0 : item * 1)
}


export const CopyToClipboard = str => {

  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  toast.success("Copied to clipboard", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});
  //this.props.localAlert("Copied to clipboard");
}

export const symbolsDisplayHelper = item => {
  //debugger;
  //Validation if is usd
  if (item.symbol === "USD") {

    return item;
  }
  //only take the second part
//  console.log("symbol in helper", item);
  let symbolPart = item.symbol !== undefined ? item.symbol.split("/")[1] : "USD";
  let symbolName = item.name !== undefined ? item.name.replace("US Dollar ", "") : "USD Dollar";
  return {
    symbol: item.symbol,
    name: symbolName,
    symbolLabel: symbolPart,
    decimal: item.decimal
  }
}

export const symbolsDisplayHelperInTransaction = (transaction, selectedExchangeRate, exchangeRates) => {
  console.log("transactions parsed exchange symbol", transaction, selectedExchangeRate, exchangeRates);
  
  let value = {
    symbol: "USD",
    name: "USD",
    symbolLabel: "USD",
    decimal: 2,
    exchange: transaction.exchangeRate,
    baseExchange: transaction.exchangeRate
  };

  //Check if the  selectExchange is different from usd
  if (selectedExchangeRate !== "USD") {
    //let's try to check if we have the date exchange if not we must show back to USD
    if (exchangeRates.length > 0) {
      let date = moment(transaction.date * 1000).format("MMM DD, yyyy");
      let exist = exchangeRates.filter(x => moment(x.dateTimeFormatUtc).format("MMM DD, yyyy") === date);
      if (exist.length > 0) {
        console.log("exchange date exist: ", date, transaction.date);

        value.symbol = selectedExchangeRate.split("/")[1];
        value.symbolLabel = value.symbol;
        value.name = value.symbol;
        value.exchange = exist[0].close*transaction.exchangeRate;
      } else {
        console.log(" date don't exist exchange date", date, transaction.date);
      }

    }



  }

  return value;
}


export const urlHasValue= (value)=>{
  let url = window.location.hash;
  return (url.includes(value));
}

export const isAnAddressRequest = value=>{
  return (value.length >=26 && value.length<=35) || value.startsWith("bc1");

  
}
export const isAnPublicAddress = value=>{
  return value.includes("xpub") || value.includes("ypub") || value.includes("zpub")
  

  
}

 /// this method is used just to extract the symbol properly when it came from the server
export const symbolParseHelper  = (selectedSymbolObj)=>{
  
  if(!selectedSymbolObj)
  {
    console.log("error loaidng seleced symbol obj", selectedSymbolObj)
    return "USD";
  }
  if(selectedSymbolObj.includes("/")){
    return (selectedSymbolObj.split("/")[1]);
  }else{
    return selectedSymbolObj;
  }
}
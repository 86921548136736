import React, { useEffect, useState } from "react";

import Settings from "../common/Settings";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlockchainHistory from "./Home/BlockchainHistory";
import Faq from "./Home/Faq";
import Footer from "./Common/Footer";
import {
  NumberFormat,
  isAnAddressRequest,
  isAnPublicAddress,
} from "../common/Helpers";
import Header from "./Common/Header";
import PreviousSearch from "./PreviousSearch";

import TransactionsByAddress from "./Transactions/TransactionsByAddress";
import TransactionsPublicAddress from "./TransactionsByPublicAddress/TransactionsPublicAddress";

import TransactionDetailsPage from "./TransactionDetails/TransactionDetailsPage";

const Home = (props) => {
  // PARAMETERS
  const { id, address, currency } = useParams();

  const [showPreviousSearch, setShowPreviousSearch] = useState(false);
  const [model, setModel] = useState({
    Platform: "",
    IP: "",
    Query: id ? id : "",
    Country: "",
    City: "",
    Browser: "",
    Latitude: "",
    Longitude: "",
    Currency: "USD",
  });

  const [state, setState] = useState({
    Symbols: [],
    SymbolExchange: [],

    GeneralTransactionInfo: null,
    TradeData: null,
    Instance: null,
    BlockchainData: null,
    firstTime: true,
    currentPriceBTCUSD: "-",

    Quantity: 10,
    Page: 0,
    showTransactionDetails: false,
  });
  const [SymbolSelected, setSymbolSelected] = useState("USD");

  const [loading, setLoading] = useState(false);


  // METHODS
  const requestGeneralInfo = async () => {
    try {
      let obj = model;
      obj.Platform = navigator.platform;
      obj.Browser = navigator.userAgent;

      let additionalDataresponse = await fetch(
        "https://api.ipgeolocation.io/ipgeo?apiKey=" +
        Settings.IPGeolocationAPiKey
      );
      let responseAdditionalData = await additionalDataresponse.json();

      if (
        responseAdditionalData !== undefined &&
        responseAdditionalData !== null
      ) {
        obj.IP = responseAdditionalData.ip;
        obj.Country = responseAdditionalData.country_name;
        obj.City = responseAdditionalData.city;
        obj.Latitude = responseAdditionalData.latitude;
        obj.Longitude = responseAdditionalData.longitude;
      }
      setModel(obj);
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    setModel({
      ...model,
      Query: value,
    });
  };


  const togglePreviousSearch = async () => {
    setShowPreviousSearch(!showPreviousSearch);
  };


  //Events
  useEffect(() => {
    //Did mount
    //Redirect because is not on secure connection
    if (
      window.location.href.includes("http://") &&
      !window.location.href.includes("localhost")
    ) {
      window.location.href = window.location.href.replace(
        "http://",
        "https://"
      );
    }

    requestGeneralInfo();

    if (model.Query) {
      //request(true);
    }

    //Will unmount
    return () => {
      console.log("Component will go =====");
    };
  }, []);


  return (
    <div>
      {showPreviousSearch ? (
        <PreviousSearch
          toggle={togglePreviousSearch}
          show={showPreviousSearch}
        />
      ) : null}
      <Header />
      <ToastContainer />
      {/* Header */}
      <header className="masthead text-white text-center">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div
              className={(id || address ? "d-none " : " ") + "col-xl-9 mx-auto"}
            >
              <h1 className="mb-5">
                Search your wallet or transaction history and see your Bitcoin
                data together with historical fiat prices.
              </h1>
            </div>

            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto d-print-none">
              <div className="form-row">
                <div className="col-12 mb-2 mb-md-0">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-lg btn-secondary"
                        type="button"
                        onClick={() => togglePreviousSearch()}
                      >
                        <i className="fas fa-history"></i>
                      </button>
                    </div>
                    <input
                      className="form-control form-control-lg"
                      onChange={handleChange}
                      value={model.Query}
                      type="text"
                      placeholder="Paste your xPub, yPub, zPub or transaction id"
                      onKeyUp={(e) => {
                        if (e.keyCode === 13 || e.key === "Enter") {

                          window.location = "/" + model.Query;
                        }
                      }}
                    />
                    <div className="input-group-append">
                      {loading ? (
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <div className="btn-group">
                          <button
                            className="btn btn-lg btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location = "/" + model.Query;
                            }}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                          {id || address ? (
                            <button
                              className="btn btn-danger "
                              title="Reset Search"
                              onClick={() => {
                                window.location = "/";
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3 text-center mx-auto mt-4">
                  {state.TradeData !== undefined &&
                    state.TradeData !== null &&
                    state.TradeData.FROMSYMBOL !== undefined ? (
                    <>
                      <p className="lead mb-0  text-light">
                        {state.TradeData.FROMSYMBOL + "/"}
                        <small className="text-mated">
                          {state.TradeData.TOSYMBOL}
                        </small>
                      </p>
                      <h2 className="text-light">
                        {NumberFormat(state.TradeData.PRICE)}
                      </h2>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="col-12 text-center my-2 text-light">
                <div
                  className="spinner-border text-light"
                  style={{ width: "3rem", height: "3rem" }}>
                  <span className="sr-only mr-2">Loading...</span>
                </div>
              </div>
            ) : null}
            {/* EMPTY STATE */}
            {state.TransactionDetails === null &&
              state.Transaction === null &&
              state.AddressDetails === null &&
              model.Query &&
              id &&
              loading === false ? (
              <div className="col-12 text-center my-4">
                <h5 className="text-light display-4">
                  No xyz pub, transaction or address found!{" "}
                </h5>
              </div>
            ) : null}
            {/* XYZPUB */}
            {isAnPublicAddress(model.Query) ? (
              <TransactionsPublicAddress
                //   result={publicAddress}
                obj={model}
                parentState={state}
                //    chooseSymbol={chooseSymbol}
                SymbolSelected={SymbolSelected}
              />
            ) : isAnAddressRequest(model.Query) ? //An address request
              <div className="col-12">
                <TransactionsByAddress
                  //     result={addressDetails}
                  obj={model}
                  parentState={state}
                  //    chooseSymbol={chooseSymbol}
                  SymbolSelected={SymbolSelected}
                />
              </div> : !isAnAddressRequest(model.Query) ? //Ann transaction request
                <TransactionDetailsPage />
                : null}


          </div>
        </div>
      </header>

      {/*HIGHLATED Sections */}
      {id || address ? null : (
        <section className="showcase bg-altdark">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-12 col-md-8 mx-auto  p-4">
                <BlockchainHistory />
              </div>
            </div>
          </div>
        </section>
      )}
      {id || address ? null : (
        <section id="faq">
          <Faq />
        </section>
      )}
      <Footer />
    </div>
  );
};

export default Home;

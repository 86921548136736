import React, { useEffect } from "react";
import { NumberFormat, FormatMoney } from "../../common/Helpers";
import moment from "moment";
import html2pdf from "html2pdf.js";
import QRCode from "react-qr-code";

const TransactionDetailsPrinting = (props) => {
  const transactionDetails = props.result;
  let totalTransactionSent = 0;
  let totalTransactionReceived = 0;
  let feeCurrency = FormatMoney(
    transactionDetails.Transaction.fee.amount *
      transactionDetails.HistoricalExchangeRate.close
  );

  useEffect(() => {
    if (props.print) {
      setTimeout(() => GeneratePDF(), 1500);
    }
  }, []);

  const GeneratePDF = () => {
    let element = document.getElementById("toprint");
    var opt = {
      margin: [0, 0, 0, 0],
      filename: "transaction_" + props.obj.Query + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  };
  return (
    <div className="col-12 mt-4 bg-light" id="toprint">
      Transaction Details:
      <p>
        <b className="text-break">{props.obj.Query}</b>
      </p>
      <hr />
      <div className="row">
        <div className="col-6 text-left">
          <small>
            <b>Fee: </b> {transactionDetails.Transaction.fee.amount + " BTC"}
          </small>
          <small className="text-muted"> / {feeCurrency} USD HBR</small>
          <br />
          <small>
            <b>Mined in Block Height: </b>{" "}
            {NumberFormat(transactionDetails.Transaction.minedInBlockHeight)}
          </small>
          <br />
          <small>
            <b>Current BTC Rate:</b>{" "}
            {FormatMoney(transactionDetails.CurrentExchangeRate.close)} USD
          </small>
        </div>
        <div className="col-6 text-right">
          {moment(transactionDetails.Transaction.timestamp * 1000).format(
            "MMM DD, YYYY hh:mm:ss A "
          ) + " GMT(-4)"}
          <br />
          {transactionDetails.Transaction.isConfirmed ? (
            <small className="badge badge-success ml-2">Confirmed</small>
          ) : null}
          <br />
          <small>
            <b>Historical BTC Rate:</b>{" "}
            {FormatMoney(transactionDetails.HistoricalExchangeRate.close)} USD
          </small>
          <br />
          <small>
            <b>Historical BTC Time:</b>{" "}
            {moment(
              transactionDetails.HistoricalExchangeRate.date * 1000
            ).format("MMM DD, YYYY hh:mm:ss A") + " GMT(-4)"}
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-6 text-left">
          <b>Sender (s)</b>
          <br />
          {transactionDetails.Transaction.senders && transactionDetails.Transaction.senders.map((sender) => {
            let historicalPrice =
              sender.amount * transactionDetails.HistoricalExchangeRate.close;
            let currentPrice =
              sender.amount * transactionDetails.CurrentExchangeRate.close;
            totalTransactionSent += sender.amount * 1;
            return (
              <div className="text-dark" key={sender.address}>
                <p className="text-left mt-2 text-break">{sender.address}</p>

                <span className="text-dark">
                  {" "}
                  {(sender.amount * 1).toFixed(8)} BTC /{" "}
                </span>
                <small>CBR: {FormatMoney(currentPrice)} - </small>
                <small>HBR: {FormatMoney(historicalPrice)} </small>
                <small>
                  = {FormatMoney(currentPrice - historicalPrice)} USD
                </small>
              </div>
            );
          })}
        </div>
        <div className="col-6 text-right">
          <b>Recepients (s)</b>
          <br />

          {transactionDetails.Transaction.recipients && transactionDetails.Transaction.recipients.map((recipient) => {
            let historicalPrice =
              recipient.amount *
              transactionDetails.HistoricalExchangeRate.close;
            let currentPrice =
              recipient.amount * transactionDetails.CurrentExchangeRate.close;
            totalTransactionReceived += recipient.amount * 1;
            return (
              <div className="text-dark" key={recipient.address}>
                <p className="text-right mt-2 text-break">
                  {recipient.address}
                </p>

                <span className="text-dark">
                  {" "}
                  {(recipient.amount * 1).toFixed(8)} BTC /{" "}
                </span>
                <small>CBR: {FormatMoney(currentPrice)} - </small>
                <small>HBR: {FormatMoney(historicalPrice)}</small>
                <small>
                  = {FormatMoney(currentPrice - historicalPrice)} USD
                </small>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-footer row">
        <div className="col-6 text-left">
          <b className="text-dark">
            {" "}
            {(totalTransactionSent * 1).toFixed(8)} BTC /{" "}
          </b>
          <small>
            CBR:{" "}
            {FormatMoney(
              totalTransactionSent *
                transactionDetails.CurrentExchangeRate.close
            )}{" "}
            -{" "}
          </small>
          <small>
            HBR:{" "}
            {FormatMoney(
              totalTransactionSent *
                transactionDetails.HistoricalExchangeRate.close
            )}{" "}
          </small>
          <small>
            ={" "}
            {FormatMoney(
              totalTransactionSent *
                transactionDetails.CurrentExchangeRate.close -
                totalTransactionSent *
                  transactionDetails.HistoricalExchangeRate.close
            )}{" "}
            USD
          </small>
        </div>
        <div className="col-6 text-right">
          <b className="text-dark">
            {" "}
            {(totalTransactionReceived * 1).toFixed(8)} BTC /{" "}
          </b>
          <small>
            CBR:{" "}
            {FormatMoney(
              totalTransactionReceived *
                transactionDetails.CurrentExchangeRate.close
            )}{" "}
            -{" "}
          </small>
          <small>
            HBR:{" "}
            {FormatMoney(
              totalTransactionReceived *
                transactionDetails.HistoricalExchangeRate.close
            )}{" "}
          </small>
          <small>
            ={" "}
            {FormatMoney(
              totalTransactionReceived *
                transactionDetails.CurrentExchangeRate.close -
                totalTransactionReceived *
                  transactionDetails.HistoricalExchangeRate.close
            )}{" "}
            USD
          </small>
        </div>
        <div className="col-12 text-dark text-left">
          <small>CBR= Current BTC Rate | HBR= Historical BTC Rate</small>
        </div>
        <div className="col-12 text-dark text-center">
          <div className="my-4">
            <QRCode
              value={window.location.href.replace("/print/", "/")}
            ></QRCode>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailsPrinting;

import React from "react"

const Pagination = props => {
  let pageNumbers = []
  for (let i = 1; i <= props.totalPages; i++) {
    pageNumbers.push(i)
  }
  console.log("Pagination", props, pageNumbers)
  const renderPageNumbers = () => {
    // if (props.totalPages > 5) {
    //   //pageNumbers = []
    //   //We need to split this result and generate it again
    //   if (props.currentPage + 4 > props.totalPages) {
    //     for (let i = props.totalPages - 5; i <= props.totalPages - 1; i++) {
    //       pageNumbers.push(i)
    //     }
    //   } else {
    //     for (let i = props.currentPage; i <= props.currentPage + 4; i++) {
    //       pageNumbers.push(i)
    //     }
    //   }
    // }
    return pageNumbers.map((number, index) => {
      return (
        <li
          className={
            props.currentPage === number
              ? "page-item mx-2 active"
              : "page-item mx-2 "
          }
          key={index}

        >
          <button
            className={
              (props.currentPage === number ? "btn-primary " : "") +
              "btn btn-sm text-light"
            }
            onClick={() => {
              props.onPageChanged(number)
            }}
          >
            {number}
          </button>
        </li>
      )
    })
  }

  return (
    <div id="pgcion">
      <ul className="pagination pg-blue">
        {props.currentPage > 0 ? (
          <li className="page-item mx-2 ">
            <button
              className="btn btn-sm  text-light"
              type="button"
              onClick={() => props.onPageChanged(0)}
            >
              &laquo;&laquo;
            </button>
          </li>
        ) : null}

        {props.currentPage > 0 ? (
          <li className="page-item mx-2 ">
            <button
              className="btn btn-sm text-light"
              type="button"
              onClick={() => props.onPageChanged(props.currentPage - 1)}
            >
              &laquo;
            </button>
          </li>
        ) : null}

        {renderPageNumbers()}

        {props.currentPage < props.totalPages - 1 ? (
          <li className="page-item mx-2 ">
            <button
              className="btn btn-sm text-light"
              type="button"
              onClick={() => props.onPageChanged(props.currentPage + 1)}
            >
              &raquo;
            </button>
          </li>
        ) : null}
        {props.currentPage < props.totalPages - 1 ? (
          <li className="page-item mx-2 ">
            <button
              className="btn btn-sm text-light"
              type="button"
              onClick={() => props.onPageChanged(props.totalPages - 1)}
            >
              &raquo;&raquo;
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  )
}
export default Pagination

import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { FormatMoney, groupBy, symbolParseHelper } from "../../common/Helpers";
import SymbolSelector from "../SymbolSelector";
import DateRangeFilter from "../Common/DateRangeFilter";
import TransactionLine from "./TransactionLine";
import Settings from "../../common/Settings";
import api from "../../common/api";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "../Common/Pagination";
import { Col, Row } from "@themesberg/react-bootstrap";

const TransactionsPublicAddress = props => {

    let queryString = new URLSearchParams(document.location.search);
    const history = useHistory();
    const [informationPending, setInformationPending] = useState(false);
    const [balance, setBalance] = useState(0);
    const [spent, setSpent] = useState(0);
    const [received, setReceived] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [quantity, setQuantity] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const recordsPerPage = [10, 20, 30, 50];

    const [showQR, setShowQR] = useState(false);

    const [summary, setSummary] = useState(null);
    const [AddressDetails, setAddressDetails] = useState(props.result);

    const { id, address, currency } = useParams();
    const [loading, setLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)

    const [filterDate, setFilterDate] = useState(null);
    const [filterDateUntil, setFilterDateUntil] = useState(null);
    const filterDateHandler = (from, until) => {
        setFilterDate(from);
        setFilterDateUntil(until);
    }

    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = async () => {
        setShowFilter(!showFilter)
    }

    const assignConversion = val => {
        console.log("is going to assign the symbol ", val);
        if (!val) {
            val = "USD";
        }
        val = symbolParseHelper(val)
        if (id === undefined) {
            return
        }
        history.push(`/${id}/${address}/${val}?page=${currentPage}`);
        //props.chooseSymbol(val);

    }
    const requestPendingInformation = async () => {
        setInformationPending(true);
        let request = await api.getAction(`PublicAddress/pending`, `address=${id}`);
        await checkCount();
        setInformationPending(false);
    }

    const checkCount = async () => {
        //setLoading(true);
        console.log("count enter")
        let requestCount = await api.getAction(`PublicAddress/TransactionCount`, `address=${id}`);
        //setLoading(false);
        console.log(requestCount);

        if (requestCount.data.status === "ok") {
            let response = requestCount.data.response;

            if (response.local < response.remote) {
                //We need to request the missing transactions 

                await requestPendingInformation();
            } else {
                //We need to request the data
                await bindDataRemote();
            }
        }
    }

    const bindDataRemote = async () => {
        console.log("trigger", new Date())
        await bindDataSummaryRemote();
        //   await bindDataDetails();
    }
    const bindDataSummaryRemote = async () => {
        setLoading(true);
        let request = await api.getAction(`PublicAddress/Summary`, `address=${id}&currency=${!currency ? "USD" : currency}`);
        setLoading(false)
        if (request.data.status === "ok") {
            let response = request.data.response;
            setSummary(response);

            setReceived(response.received);
            setSpent(response.spent);
            setBalance(response.received - response.spent);
        }
    }

    const bindDataDetails = async () => {

        try {
            console.log("details called", currency)

            let pageToCall = currentPage - 1;
            if (pageToCall < 0) {
                pageToCall = 0;
            }
            let query = `address=${id}&currency=${!currency ? "USD" : currency}&page=${pageToCall}&quantity=${quantity}`
            setIsLoadingDetails(true);
            let request = await api.getAction(`PublicAddress/details`, query);

            if (request.data.status === "ok") {
                let response = request.data.response;
                setAddressDetails(response);
                setTotalPages(response.quantityOfPages);
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsLoadingDetails(false)
        }
    }


    const generalInformationUI = () => {
        try {

            let balance = summary.received - summary.spent;

            if (balance <= 0) {
                balance = 0;
            }
            const symbolSelected = symbolParseHelper(summary.currencySymbol);


            const calcConversion = (usdValue, fiatValue) => {
                if (summary.currencySymbol === "USD") {
                    return usdValue;
                } else {
                    return usdValue * fiatValue;
                }
            }
            return (<p className="text-light text-left">
                Balance: {((1) * balance).toFixed(8)} BTC
                <span className="text-muted">/ {FormatMoney(balance * calcConversion(summary.currentExchangeRate.close, summary.forexCurrentExchangeRate?.close))} {symbolSelected}</span>
                <br />
                <small className="text-success ">
                    Received: {((1) * summary.received).toFixed(8)} BTC
                    <span className="text-muted">/ {FormatMoney(summary.received * calcConversion(summary.currentExchangeRate.close, summary.forexCurrentExchangeRate?.close))} {symbolSelected}</span>
                </small>
                <small className="text-danger ml-2">
                    Spent: {((1) * summary.spent).toFixed(8)} BTC
                    <span className="text-muted">/ {FormatMoney(summary.spent * calcConversion(summary.currentExchangeRate.close, summary.forexCurrentExchangeRate?.close))} {symbolSelected}</span>
                </small>

            </p>);
        } catch (ex) {
            console.error(ex);
            return <></>
        }

    }

    const onPageChanged = number => {
        setCurrentPage(number);
        console.log("current page changed", number)
        //queryString.append("page", number);
        if (id === undefined) {
            return;
        }
        let urlPath = `/${id}/${address}/${currency}?page=${number}`
        history.push(urlPath);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

    }



    useEffect(() => {
        console.log("Enter on id change", id)
        checkCount();
    }, [id])


    useEffect(() => {
        if (currency === undefined) {
            return;
        }
        console.log("currency", currency)
        bindDataRemote();
        bindDataDetails();
    }, [currency])

    useEffect(() => {
        console.log("current page", currentPage)
        bindDataDetails();
    }, [currentPage])

    useEffect(() => {
        if (queryString.get("page") !== null) {
            setCurrentPage(queryString.get("page") * 1)
        }
        console.log("params 2", queryString.get("page"))
    }, [queryString.get("page")])

    return (<>
        {showFilter ?
            <DateRangeFilter toggle={toggleFilter} show={showFilter} filterDateHandler={filterDateHandler} />
            : null}

        {informationPending ?
            <p className="text-center my-4">
                <div className="spinner-border mr-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                We are loading remote data, please wait
            </p> : null}
        {loading ? (
            <div className="col-12 text-center my-2 text-light">
                <div
                    className="spinner-border text-light"
                    style={{ width: "3rem", height: "3rem" }}
                >
                    <span className="sr-only mr-2">Loading...</span>
                </div>
            </div>
        ) : null}
        <div className="col-12 mt-4 table-responsive transactionList">
            <div className="card shadow-sm bg-dark">
                {/* HEADER */}
                {summary ?
                    <div className="card-header ">
                        <div className="row">
                            <div className="col">
                                <h4 className="text-light text-left">Transactions

                                    <small className="text-light ml-2"> ({summary.amountOfTransactions})</small>

                                </h4>

                                {generalInformationUI()}
                            </div>
                            <div className="ms-auto col-auto text-right">
                                <div className="input-group">
                                    {/* LOAD SYMBOLS */}
                                    <div className='input-group-prepend d-none'>
                                        <button className='btn btn-primary btn-sm' onClick={() => toggleFilter()}>
                                            <i className='fa fa-filter'></i>
                                        </button>
                                    </div>
                                    <SymbolSelector
                                        SymbolSelected={summary.currencySymbol}
                                        assignConversion={assignConversion} />
                                    <div className='input-group-append'>

                                        <div className='btn-group'>
                                            <a className="btn btn-sm btn-primary text-light "
                                                title="Export to CSV"
                                                alt="Export to CSV"
                                                download href={`${Settings.baseURl}RequestHistoric/GenerateCSV?publicKey=${props.obj.Query}&currency=${currency}`}>
                                                <i className="fas fa-file-csv"></i>
                                            </a>
                                            <a className="btn btn-sm btn-primary text-light  "
                                                title="Export to excel"
                                                alt="Export to excel"
                                                download href={`${Settings.baseURl}RequestHistoric/GenerateExcel?publicKey=${props.obj.Query}&currency=${currency}`}>
                                                <i className="fas fa-file-excel"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                {isLoadingDetails ? (
                    <div className="col-12 text-center my-2 text-light">
                        <div
                            className="spinner-border text-light"
                            style={{ width: "3rem", height: "3rem" }}
                        >
                            <span className="sr-only mr-2">Loading...</span>
                        </div>
                    </div>
                ) : null}
                {/* BODY */}
                {!isLoadingDetails && AddressDetails !== null ?
                    <div className="card-body ">
                        {/*DISPLAY ON BIGSCREENS ONLY */}
                        <div className=" d-none d-md-block d-lg-block">
                            <div className="row">
                                <div className="col-1"><small>#</small></div>
                                <div className="col-1 text-left"><small>Date</small></div>
                                <div className="col-4 text-left"><small>Hash</small></div>
                                <div className="col-2 text-left pl-0"><small>Amount Transacted</small></div>

                                <div className="col-2 text-left pl-0"><small>1 BTC</small></div>
                                <div className="col-auto ml-auto text-left"><small>Tx Fee</small></div>
                            </div>
                        </div>
                        {/* RECORDS */}
                        {AddressDetails && AddressDetails.transactionDetails && AddressDetails.transactionDetails.map((item, index) => {
                            //   return (<></>)
                            //    console.log("entro")
                            return (<TransactionLine key={index} parentState={AddressDetails}

                                Item={item} index={index}
                                recordsPerPage={quantity}
                                currentPage={currentPage - 1}
                            />);
                        })}

                        <Row>
                            <Col>
                                <Pagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChanged={onPageChanged}
                                />
                            </Col>
                            <Col className="text-right col-auto">
                                <select className="form-control"
                                    value={quantity}
                                    onChange={e => {
                                        setQuantity(e.target.value)
                                        setCurrentPage(0);
                                    }}
                                >
                                    {recordsPerPage.map((item, index) => {
                                        return (<option value={item} key={index}>{item}</option>)
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </div>
                    : null}
                <div className='card-footer'>
                    <div className="col-12 text-light text-center">
                        {showQR ?
                            <div className='my-4 bg-light  p-2 mx-auto text-center' style={{ width: "280px" }}>

                                <QRCode value={window.location.href}></QRCode>
                            </div> : null}
                        <button className={(!showQR ? "" : "d-none ") + 'btn btn-primary '} type="button"
                            title='Show QR Code'
                            onClick={() => {
                                setShowQR(!showQR)
                            }}>
                            <i className='fas fa-qrcode'></i>
                        </button>
                        {/* QR CodE */}
                    </div>
                </div>

            </div>


        </div>
    </>)
}

export default TransactionsPublicAddress;
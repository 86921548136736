import React from 'react';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import BitcoinHistory from './BitcoinHistory';

const BitcoinHistoryPage = props => {
    return (<>
        <Header />
        <div className="col-12 col-md-9 col-lg-8 mx-auto  p-4">
            <BitcoinHistory />
        </div>
        <Footer />
    </>
    )
}

export default BitcoinHistoryPage;
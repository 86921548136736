import React, { useEffect, useState } from "react";
import ConfirmedTransactionsDetailsConversational from "./ConfirmedTransactionsDetailsConversational";
import UnconfirmedTransactionsDetailsConversational from "./UnconfirmedTransactionsDetailsConversational";
import { useParams } from "react-router-dom";
import api from "../../common/api";
const TransactionDetailsPage = props => {

    let { id, address, currency } = useParams();
    const [model, setModel] = useState({
        Platform: "",
        IP: "",
        Query: id ? id : "",
        Country: "",
        City: "",
        Browser: "",
        Latitude: "",
        Longitude: "",
        Currency: "USD",
      });

    const [transactionDetails, setTransactionDetails] = useState(null);
    const [SymbolSelected, setSymbolSelected] = useState("USD");
    const [loading, setLoading] = useState(false)
    const chooseSymbol = (obj) => {
        setModel({
            ...model,
            Currency: obj,
        });
    };


    const request = async (reset) => {
        try {

           
            setLoading(true);

            //DETECT THE TYPE OF REQUEST TO DO
            let requestAddress = "";
            //Request transaction Details
            requestAddress = "TransactionDetails/QueryV2";

            let response = await api.postAction(requestAddress, model);

            if (response.status === 200) {

                setTransactionDetails(response.data);

            }


        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };
    useEffect(()=>{
        console.log(id, address, currency);
        request();
    },[id, model.Currency])
   
    if(!transactionDetails){
        return (<></>)
    }
    return (<>
        {transactionDetails.transactionDetails.confirmations > 0 ? (
            <ConfirmedTransactionsDetailsConversational
                result={transactionDetails}
                obj={model}
                //       parentState={state}
                chooseSymbol={chooseSymbol}
                SymbolSelected={SymbolSelected}
            />
        ) : (
            <UnconfirmedTransactionsDetailsConversational
                result={transactionDetails}
                obj={model}
                //    parentState={state}
                chooseSymbol={chooseSymbol}
                SymbolSelected={SymbolSelected}
            />
        )}
    </>)
}

export default TransactionDetailsPage;